const ACTIVITIES = [
	{
		label: 'Guided Fishing', value: 'GuidedFishing', showGuides: true, showUnits: false,
	},
	{
		label: 'Hunting', value: 'Hunting', showGuides: true, showUnits: false,
	},
	{
		label: 'Shooting Range — Rife or Pistol', value: 'Shooting', showGuides: false, showUnits: true,
	},
	{
		label: 'Hiking/Backpacking', value: 'HikingBackpacking', showGuides: true, showUnits: false,
	},
	{
		label: 'Horseback Riding', value: 'HorsebackRiding', showGuides: true, showUnits: true,
	},
	{
		label: 'Hay, Sleigh, or Wagon Rides', value: 'WagonRides', showGuides: true, showUnits: true,
	},
	{
		label: 'Lodging/Cabin Rentals', value: 'Lodging', showGuides: false, showUnits: true,
	},
	{
		label: 'Retail Store', value: 'RetailStore', showGuides: false, showUnits: true,
	},
	{
		label: 'Bike Rentals', value: 'BikeRental', showGuides: true, showUnits: true,
	},
	{
		label: 'Mountain Bike Riding', value: 'MountainBiking', showGuides: true, showUnits: true,
	},
	{
		label: 'Road Cycling', value: 'RoadCycling', showGuides: true, showUnits: true,
	},
	{
		label: 'Boating', value: 'Boating', showGuides: true, showUnits: true,
	},
	{
		label: 'Jet Skis or Wave Runners', value: 'JetSki', showGuides: false, showUnits: true,
	},
	{
		label: 'River Tubing', value: 'RiverTubing', showGuides: true, showUnits: true,
	},
	{
		label: 'Sea Kayak Tours/Rentals', value: 'SeaKayak', showGuides: true, showUnits: true,
	},
	{
		label: 'Waterskiing', value: 'WaterSki', showGuides: false, showUnits: false,
	},
	{
		label: 'Whitewater Rafting', value: 'WhitewaterRafting', showGuides: true, showUnits: true,
	},
	{
		label: 'SCUBA Diving', value: 'Scuba', showGuides: true, showUnits: false,
	},
	{
		label: 'Cross Country Skiing', value: 'CrossCountrySki', showGuides: true, showUnits: false,
	},
	{
		label: 'Dog Sled Tours', value: 'DogSled', showGuides: false, showUnits: false,
	},
	{
		label: 'Snowshoeing', value: 'Snowshoe', showGuides: true, showUnits: false,
	},
	{
		label: 'ATV-guided', value: 'ATVGuided', showGuides: true, showUnits: true,
	},
	{
		label: 'ATV-unguided', value: 'ATVUnguided', showGuides: false, showUnits: true,
	},
	{
		label: 'Snowmobiles-guided', value: 'SnowmobileGuided', showGuides: true, showUnits: true,
	},
	{
		label: 'Snowmobiles-unguided', value: 'SnowmobileUnguided', showGuides: false, showUnits: true,
	},
	{
		label: 'Climbing Wall', value: 'WallClimbing', showGuides: false, showUnits: false,
	},
	{
		label: 'Rock Climbing', value: 'RockClimbing', showGuides: true, showUnits: false,
	},
	{
		label: 'Paintball', value: 'Paintball', showGuides: false, showUnits: false,
	},
	{
		label: 'Youth Camps or Programs', value: 'YouthCamp', showGuides: false, showUnits: false,
	},
];

export default ACTIVITIES;
