import React, { useState, useContext, useEffect } from 'react';
import { Numbers, differenceInDays, formatToStandardDate } from '@buddy-technology/buddy_helpers';
import { AppContext } from '../context';
import { Input, Spinner } from '../components/ReactRainbow';
import IF from '../components/IF';

import BuddyAPI from '../models/BuddyAPI';

const BuddyInsurance = ({
	venue, priceModel, titleOverRide, helperCopy,
}) => {
	const {
		appState: {
			product,
			dateRange,
			isPurchasing = false,
			shouldRefreshPricing = true,
			pricing = null,
			selectedOffering,
		},
		displayError,
		setAppState,
	} = useContext(AppContext);

	const isTicketCancellation = selectedOffering.includes('TICKET_CANCELLATION');

	const [currentPricing, setPricing] = useState(pricing);
	const getQuote = async () => {
		try {
			const options = {
				who: 'ME',
				state: venue?.state,
				venueName: venue?.name,
				offering: selectedOffering,
			};
			if (isTicketCancellation) {
				const days = differenceInDays(dateRange[1], dateRange[0]);
				options.totalTicketPrice = priceModel.calculateCost({ days, type: product?.name });
			}
			if (dateRange.length === 1) {
				options.duration = 'DAY';
			} else {
				options.duration = 'MANUAL';
				options.startDate = formatToStandardDate(dateRange[0]);
				options.endDate = formatToStandardDate(dateRange[1]);
			}
			const quote = await BuddyAPI.getQuote(options);
			setAppState({ pricing: quote, shouldRefreshPricing: false });
			setPricing(quote);
		} catch (error) {
			setAppState({ shouldRefreshPricing: false });
			displayError(error?.message || error, true);
		}
	};

	// get dat quote son
	useEffect(() => {
		if (shouldRefreshPricing) {
			setPricing(null);
			getQuote();
		}
	}, [shouldRefreshPricing]);

	const handleCheck = ({ target }) => setAppState({ isPurchasing: target?.checked });

	const title = isTicketCancellation
		? `${venue?.name ? `${venue.name} ` : ''}Registration Cancellation Insurance`
		: `Do you want to be insured for accidents${product?.verb ? ` while ${product.verb}` : ''}?`;

	return (
		<div className="px-5">
			<div className="text-4xl text-left">{titleOverRide || title}</div>
			<div className="mb-4">
				<IF condition={isTicketCancellation}>
					<>
						<p className="mb-4">
							Get reimbursed the cost of your registration, up to $5,000 if you are unable to attend due any of the following covered reasons stated in the policy, including serious injury or illness of you or a family member, death of a family member, vehicle/traffic accidents and more.
						</p>
						<p className="mb-4 text-sm">
							By checking the box below and purchasing this policy, I acknowledge that
							I have read, understand and agree to the policy terms, conditions and
							exclusions, Electronic Signature Conditions, and Electronic Documents
							Terms and Conditions.
							{' '}
							<a
								href="https://buddyinsurance.com/policy-info/registration-cancellation/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-500 underline"
							>
								Click here for full plan & benefits details, and disclosures
							</a>
							.
						</p>
					</>
				</IF>
				<IF condition={!isTicketCancellation}>
					<>
						<p className="mb-4">
							{helperCopy}
							{' '}
							With Buddy, you get up to $50,000 in injury/AD&D protection. Benefits for a covered
							incident are paid directly to you, regardless of other health insurance.
						</p>
						<p className="mb-4 text-sm">
							By checking the box below and purchasing this policy, I acknowledge that
							I have read, understand and agree to the policy terms, conditions and
							exclusions, Electronic Signature Conditions, and Electronic Documents
							Terms and Conditions.
							{' '}
							<a
								href="https://buddyinsurance.com/partners/policy-info/?state=VA"
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-500 underline"
							>
								Click here for full plan & benefits details, and disclosures
							</a>
							.
						</p>
					</>
				</IF>
			</div>
			<div className="flex flex-col">
				<div className="flex flex-row">
					<div className="flex flex-col text-sm my-auto">
						Your policy for the selected timeframe will cost
					</div>
					<div className="flex flex-col font-bold text-2xl ml-4 my-auto px-4 bg-green-500 border-2 rounded-xl text-white">
						{(currentPricing && Numbers.toUSD(currentPricing)) || (
							<Spinner
								variant="neutral"
								size="medium"
								className="my-auto"
								style={{ position: 'relative', top: '15px' }}
								assistiveText="loading-spinner"
							/>
						)}
					</div>
				</div>
				<div className="flex flex-row">
					<Input
						checked={isPurchasing}
						onChange={handleCheck}
						className="mt-4"
						type="checkbox"
						label="Yes, add it."
					/>
				</div>
				<div className="flex flex-row justify-end text-xs">
					<span className="my-auto">Insurance offer powered by</span>
					<a
						href="https://buddyinsurance.com"
						target="_blank"
						rel="noopener noreferrer"
						style={{ backgroundColor: '#dc5648', borderColor: '#dc5648' }}
						className="flex flex-col font-bold text-sm ml-1 my-auto px-4  border rounded-full text-white hover:text-white hover:no-underline"
					>
						Buddy
					</a>
				</div>
			</div>
		</div>
	);
};

export default BuddyInsurance;
