import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, ProgressBar } from './ReactRainbow';

const ProgressControl = ({
	disablePrev = false,
	disableNext = false,
	percent = 0,
	className,
	onNext = () => {},
	onPrev = () => {},
}) => {
	const classes = `flex flex-row justify-between mx-auto bg-white ${className}`;
	return (
		<div className={classes}>
			<Button variant="brand" disabled={disablePrev} onClick={onPrev}>
				<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
				Prev
			</Button>
			<ProgressBar
				className="my-auto mx-8"
				value={100 * percent}
				size="large"
			/>

			<Button variant="brand" disabled={disableNext} onClick={onNext}>
				Next
				<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
			</Button>
		</div>
	);
};

export default ProgressControl;
