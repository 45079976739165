import {
	useContext, useEffect, cloneElement,
} from 'react';
import { Router, navigate, useLocation } from '@reach/router';
import { AppContext } from '../context';
import { Card, Application } from '../components/ReactRainbow';
import Header from '../components/Header';
import ProgressControl from '../components/ProgressControl';

const MainApp = ({
	uri, views, theme, header, logo, location: { pathname },
}) => {
	const location = useLocation();

	const {
		appState,
		appState: { offerPlacement, longform },
		displayError,
	} = useContext(AppContext);

	const formViews = views.filter(
		(view) => !['buddy-insurance', 'checkout', 'thanks'].includes(view.path),
	);
	const secondaryViews = views.filter((view) => ['buddy-insurance', 'checkout', 'thanks'].includes(view.path));
	const longFormViews = [{ path: '/' }].concat(secondaryViews);

	// moves views around to put offer before info collection
	const placeOfferFirst = () => {
		const viewsOfferBefore = [...views];
		const offer = viewsOfferBefore.filter((view) => ['buddy-insurance'].includes(view.path))[0];
		const offerIndex = viewsOfferBefore.findIndex((view) => view.path === 'buddy-insurance');
		const whoAreYouIndex = viewsOfferBefore.findIndex((view) => view.path === 'customer-info');
		viewsOfferBefore.splice(offerIndex, 1);
		viewsOfferBefore.splice(whoAreYouIndex, 0, offer);
		return viewsOfferBefore;
	};

	// removes offer from flow
	const removeOffer = () => {
		const allViews = [...views];
		const viewsNoOffer = allViews.filter((view) => view.path !== 'buddy-insurance');
		return viewsNoOffer;
	};

	let appViews = null;

	if (longform) {
		appViews = longFormViews;
	} else if (offerPlacement === 'before-info') {
		appViews = placeOfferFirst();
	} else if (offerPlacement === 'with-checkout') {
		appViews = removeOffer();
	} else {
		appViews = views;
	}

	const currentPath = pathname.substring(`${uri}/`.length);
	const currentView = appViews.find((appView) => appView.path === currentPath);
	const position = appViews.findIndex((el) => currentPath === el.path);

	const scrollToTop = () => window?.scrollTo({
		top: 0,
		behavior: 'smooth',
	});

	useEffect(() => {
		if (currentPath === '') {
			navigate(`${uri}/${appViews[0].path}`, { replace: true });
		}
		scrollToTop();
	}, [uri, currentPath]);

	const moveBackward = (event, direction = 1) => {
		const destination = appViews[position - direction];
		if (destination) {
			navigate(`${uri}/${destination.path}`);
		}
	};

	const moveForward = (event, direction = 1) => {
		let canPass = !longform ? currentView.errorCheck(appState) : { ok: true };
		if (longform) {
			const errors = [];
			formViews.forEach((el) => {
				const result = el.errorCheck(appState);
				if (result.error) {
					errors.push(result.error, ' ');
				}
			});
			if (errors.length) {
				canPass = { ok: false, error: errors };
			}
		}
		if (!canPass.ok) {
			displayError(canPass.error, 'Error');
			return;
		}
		const destination = appViews[position + direction];
		if (destination) {
			navigate(`${uri}/${destination.path}`);
		}
	};

	const isInitialView = position === 0;

	const defaultFooter = location.pathname.includes('/thanks') ? null : (
		<ProgressControl
			disablePrev={isInitialView || currentView?.disablePrev}
			disableNext={position === appViews.length - 2 || currentView?.disableNext}
			percent={currentView?.progress || position / (appViews.length - 2)}
			onNext={moveForward}
			onPrev={moveBackward}
		/>
	);

	const longFormFooter = location.pathname.includes('/thanks') ? null : (
		<ProgressControl
			disablePrev={isInitialView || currentView?.disablePrev}
			disableNext={position === longFormViews.length - 2 || currentView?.disableNext}
			percent={currentView?.progress || position / (longFormViews.length - 2)}
			onNext={moveForward}
			onPrev={moveBackward}
		/>
	);

	const footer = longform ? longFormFooter : defaultFooter;

	const LongView = () => (
		<div>
			{formViews.map((el) => (
				<div className="mt-12" key={`${el.path}-container`}>
					{cloneElement(el.component, {
						key: el.path,
						id: el.path,
					})}
				</div>
			))}
		</div>
	);

	return (
		<Application className="w-full" theme={theme}>
			<Header theme={theme} header={header} logo={logo} />
			<div className="w-11/12 md:w-3/4 mx-auto mt-10">
				<Card
					className={currentView?.className}
					footer={currentView?.hideFooter ? null : footer}
				>
					{!longform ? (
						<Router className="flex flex-col p-5">
							{views.map((el) => cloneElement(el.component, {
								path: `/${el.path}`,
								default: el.default,
								navigateNext: moveForward,
								navigateBack: moveBackward,
								key: el.path,
								id: el.path,
							}))}
						</Router>
					) : (
						<Router className="flex flex-col p-5 py-16 max-w-4xl mx-auto">
							<LongView path="/" />
							{secondaryViews.map((el) => cloneElement(el.component, {
								path: `/${el.path}`,
								default: el.default,
								navigateNext: moveForward,
								navigateBack: moveBackward,
								key: el.path,
								id: el.path,
							}))}
						</Router>
					)}
				</Card>
			</div>
		</Application>
	);
};

export default MainApp;
