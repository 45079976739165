import React from 'react';
import { RadioButtonGroup } from './ReactRainbow';
import IF from './IF';

const YesNo = ({
	name,
	label,
	value,
	onChange,
	required,
	includeNA,
	className = 'md:w-1/6',
	variant = 'brand',
}) => {
	const options = [{ value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }];
	if (includeNA) {
		options.push({ value: 'NA', label: 'N/A' });
	}
	return (
		<div className="mt-4">
			<label className="rainbow-label-lookalike pl-2 mr-3 block">
				<IF condition={required}>
					<abbr title="required" className="rainbow-asterisk">
						*
						{' '}
					</abbr>
				</IF>
				{label}
			</label>
			<RadioButtonGroup
				className={className}
				options={options}
				value={value}
				onChange={onChange}
				variant={variant}
				name={name}
				hideLabel
			/>
		</div>
	);
};

export default YesNo;
