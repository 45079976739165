import React, { useState } from 'react';
import { Modal } from './components/ReactRainbow';

export const DEFAULT_STATE = {
	authData: null,
	duration: 'oneday',
	dateRange: [new Date()],
	isPurchasing: false,
	pricing: null,
	shouldRefreshPricing: true,
	customer: {},
	longform: false,
	offerPlacement: 'after-info',
	offerings: [],
	selectedOffering: 'BUDDY_ACCIDENT_V2',
	test: null,
};

export const AppContext = React.createContext({
	appState: { authData: null },
	setAppState: () => {},
});

export const AppProvider = ({ children }) => {
	const [appState, setState] = useState(DEFAULT_STATE);
	const [modalOptions, setModalOptions] = useState({
		isOpen: false,
		children: null,
	});

	const displayError = (error, showErrorTitle) => {
		let errorMsg = error?.message || error;
		if (Array.isArray(errorMsg)) {
			// eslint-disable-next-line quotes
			errorMsg = errorMsg.join('\n');
		}
		if (typeof errorMsg !== 'string') {
			// eslint-disable-next-line no-console
			console.log(error);
			errorMsg = 'An unknown error occurred.';
		}
		const ErrorComponent = (
			<div className={`px-3 ${showErrorTitle ? 'pb-5' : 'py-5'}`} style={{ whiteSpace: 'pre-wrap' }} data-testid="error-modal">
				{errorMsg}
			</div>
		);
		setModalOptions((current) => ({
			...current,
			isOpen: true,
			children: ErrorComponent,
			title: showErrorTitle ? 'Error' : null,
		}));
	};

	const resetApp = () => {
		setState(DEFAULT_STATE);
	};

	const update = (updates) => setState((current) => ({ ...current, ...updates }));

	return (
		<AppContext.Provider
			value={{
				appState, setAppState: update, displayError, resetApp,
			}}
		>
			<Modal
				{...modalOptions}
				onRequestClose={() => setModalOptions({ isOpen: false })}
			/>
			{children}
		</AppContext.Provider>
	);
};
