import React, { useContext } from 'react';

import { AppContext } from '../../context';

const Thanks = () => {
	const {
		appState: { orderID },
	} = useContext(AppContext);

	return (
		<div className="mx-auto mb-5 w-5/6">
			<div className="text-4xl text-center mb-4 px-4">Thanks!</div>
			<p className="text-center w-1/2 mx-auto mt-4">
				Your Buddy Insurance order id is
				{' '}
				<span className="font-bold">{orderID || 'o-130B8dfl34'}</span>
				{' '}
				and your policy should
				already be waiting for you in your email.
			</p>
		</div>
	);
};

export default Thanks;
