import React, { useContext, useState } from 'react';
import { properCase } from '@buddy-technology/buddy_helpers';
import { Input, Button } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import BuddyAPI from '../../models/BuddyAPI';

function FraudStatement({ navigateNext }) {
	const {
		appState,
		setAppState,
		displayError,
	} = useContext(AppContext);

	const [isLoading, setIsLoading] = useState(false);

	const {
		signature, date, title, activities = [], guides = [], motorBoats = [], boats = [],
		losses = [], riskManagerEmail = 'vogey@iambuddy.com',
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	const obj = {};

	activities.forEach((act) => {
		obj[`#OfGuides${act.name}`] = act.guides || '';
		obj[`#OfUnits${act.name}`] = act.units || '';
		obj[`revenues${act.name}`] = act.revenues || '';
		obj[`userDays${act.name}`] = act.userDays || '';
	});

	guides.forEach((guide, i) => {
		obj[`guide${i}Name`] = guide.name || '';
		obj[`guide${i}YearsOfExperience`] = guide.experience || '';
		obj[`guide${i}FirstAidQualifications`] = guide.firstAid || '';
	});

	motorBoats.forEach((boat, i) => {
		const prefix = 'watercraftLiabilitySection';
		obj[`${prefix}Year${i}`] = boat.year || '';
		obj[`${prefix}MakeModel${i}`] = boat.makeAndModel || '';
		obj[`${prefix}Length${i}`] = boat.length || '';
		obj[`${prefix}HP${i}`] = boat.hp || '';
		obj[`${prefix}OBIBIO${i}`] = boat.engineType || '';
		obj[`${prefix}Pass${i}`] = boat.numOfPassengers || '';
		obj[`${prefix}Guided${i}`] = boat.isGuided || '';
	});

	boats.forEach((boat) => {
		obj[`max#Used${properCase(boat.type)}`] = boat.maxUsed || '';
		obj[`avg#Used${properCase(boat.type)}`] = boat.avgUsed || '';
	});

	losses.forEach((loss, i) => {
		const prefix = 'lossHistory';
		obj[`${prefix}Date${i}`] = loss.date || '';
		obj[`${prefix}IncidentDescription${i}`] = loss.description || '';
		obj[`${prefix}AmountPaidReserved${i}`] = loss.amountPaid || '';
	});

	obj.email = riskManagerEmail;

	const handleSubmit = async () => {
		setIsLoading(true);
		const payload = Object.assign(appState, obj);
		try {
			const { application } = await BuddyAPI.orderCommercial(payload);
			setAppState({ orderID: application });
			navigateNext();
		} catch (error) {
			displayError(error?.message || error, 'Error');
		}
		setIsLoading(false);
	};

	return (
		<div className="mb-5">
			<h1 className="text-center font-bold underline">
				FRAUD STATEMENT AND SIGNATURE SECTIONS
			</h1>
			<div className="px-3 md:px-8 mt-4">
				<div className="font-bold">
					<p>
						The Undersigned states that he/she is an authorized representative of the Applicant and declares to the best of his/her knowledge and belief
						and after reasonable inquiry, that the statements set forth in this Application (and any attachments submitted with this Application) are true
						and complete and may be relied upon by Company * in quoting and issuing the policy. If any of the information in this Application changes
						prior to the effective date of the policy, the Applicant will notify the Company of such changes and the Company may modify or withdraw the
						quote or binder.
					</p>
					<br />
					<p>
						The signing of this Application does not bind the Company to offer, or the Applicant to purchase the policy.
						*Company refers collectively to Philadelphia Indemnity Insurance Company and Tokio Marine Specialty Insurance Company
					</p>
					<br />
					<p>
						VIRGINIA APPLICANT: READ YOUR POLICY. THE POLICY OF INSURANCE FOR WHICH THIS APPLICATION IS BEING MADE, IF ISSUED,
						MAY BE CANCELLED WITHOUT CAUSE AT THE OPTION OF THE INSURER AT ANY TIME IN THE FIRST 60 DAYS DURING WHICH IT IS IN
						EFFECT AND AT ANY TIME THEREAFTER FOR REASONS STATED IN THE POLICY.
					</p>
				</div>
				<h2 className="text-center font-bold my-4">
					FRAUD NOTICE STATEMENTS
				</h2>
				<p>
					ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN APPLICATION FOR
					INSURANCE CONTAINING ANY MATERIALLY FALSE INFORMATION OR CONCEALS, FOR THE PURPOSE OF MISLEADING, INFORMATION
					CONCERNING ANY FACT MATERIAL THERETO COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME AND SUBJECTS THAT PERSON TO
					CRIMINAL AND CIVIL PENALTIES (IN OREGON, THE AFOREMENTIONED ACTIONS MAY CONSTITUTE A FRAUDULENT INSURANCE ACT WHICH MAY
					BE A CRIME AND MAY SUBJECT THE PERSON TO PENALTIES). (IN NEW YORK, THE CIVIL PENALTY IS NOT TO EXCEED FIVE THOUSAND DOLLARS
					($5,000) AND THE STATED VALUE OF THE CLAIM FOR EACH SUCH VIOLATION).
					<span className="font-bold">
						{' '}
						(NOT APPLICABLE IN AL, AR, AZ, CO, DC, FL, KS, LA, ME, MD, MN,
						NM, OK, PA, RI, TN, VA, VT, WA AND WV)
					</span>
					.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE IN AL, AR, AZ, DC, LA, MD, NM, RI AND WV:</span>
					{' '}
					ANY PERSON WHO KNOWINGLY (OR WILLFULLY IN MD) PRESENTS A FALSE OR
					FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR WHO KNOWINGLY (OR WILLFULLY IN MD) PRESENTS FALSE INFORMATION IN AN
					APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES OR CONFINEMENT IN PRISON.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE IN COLORADO:</span>
					{' '}
					IT IS UNLAWFUL TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION TO AN
					INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING OR ATTEMPTING TO DEFRAUD THE COMPANY. PENALTIES MAY INCLUDE
					IMPRISONMENT, FINES, DENIAL OF INSURANCE AND CIVIL DAMAGES. ANY INSURANCE COMPANY OR AGENT OF AN INSURANCE COMPANY WHO
					KNOWINGLY PROVIDES FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION TO A POLICYHOLDER OR CLAIMANT FOR THE PURPOSE
					OF DEFRAUDING OR ATTEMPTING TO DEFRAUD THE POLICYHOLDER OR CLAIMANT WITH REGARD TO A SETTLEMENT OR AWARD PAYABLE FROM
					INSURANCE PROCEEDS SHALL BE REPORTED TO THE COLORADO DIVISION OF INSURANCE WITHIN THE DEPARTMENT OF REGULATORY
					AGENCIES.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE IN FLORIDA AND OKLAHOMA:</span>
					{' '}
					ANY PERSON WHO KNOWINGLY AND WITH INTENT TO INJURE, DEFRAUD, OR DECEIVE ANY INSURER
					FILES A STATEMENT OF CLAIM OR AN APPLICATION CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF A
					FELONY (IN FL, A PERSON IS GUILTY OF A FELONY OF THE THIRD DEGREE).
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE KANSAS:</span>
					{' '}
					AN ACT COMMITTED BY ANY PERSON WHO, KNOWINGLY AND WITH INTENT TO DEFRAUD, PRESENTS, CAUSES TO BE
					PRESENTED OR PREPARES WITH KNOWLEDGE OR BELIEF THAT IT WILL BE PRESENTED TO OR BY AN INSURER, PURPORTED INSURER, BROKER
					OR ANY AGENT THEREOF, ANY WRITTEN, ELECTRONIC, ELECTRONIC IMPULSE, FACSIMILE, MAGNETIC, ORAL, OR TELEPHONIC COMMUNICATION
					OR STATEMENT AS PART OF, OR IN SUPPORT OF, AN APPLICATION FOR THE ISSUANCE OF, OR THE RATING OF AN INSURANCE POLICY FOR
					PERSONAL OR COMMERCIAL INSURANCE, OR A CLAIM FOR PAYMENT OR OTHER BENEFIT PURSUANT TO AN INSURANCE POLICY FOR
					COMMERCIAL OR PERSONAL INSURANCE WHICH SUCH PERSON KNOWS TO CONTAIN MATERIALLY FALSE INFORMATION CONCERNING ANY FACT
					MATERIAL THERETO; OR CONCEALS, FOR THE PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE KENTUCKY:</span>
					{' '}
					ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSONS
					FILES AN APPLICATION FOR INSURANCE CONTAINING ANY MATERIALLY FALSE INFORMATION OR CONCEALS, FOR THE PURPOSE OF
					MISLEADING, INFORMATION CONCERNING ANY MATERIAL THERETO COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE MAINE, TENNESSEE, VIRGINIA AND WASHINGTON:</span>
					{' '}
					IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE OR MISLEADING
					INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES MAY INCLUDE IMPRISONMENT,
					FINES OR A DENIAL OF INSURANCE BENEFITS.
				</p>
				<br />
				<p>
					<span className="font-bold">APPLICABLE NEW YORK:</span>
					{' '}
					ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON
					FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY MATERIALLY FALSE INFORMATION, OR CONCEALS FOR THE
					PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A
					CRIME AND SHALL BE SUBJECT TO A CIVIL PENALTY NOT TO EXCEED FIVE THOUSAND DOLLARS AND THE STATE VALUE OF THE CLAIM FOR EACH
					SUCH VIOLATION.
				</p>
			</div>
			<form className="px-3 md:px-8 mt-8">
				<Input
					label="Title (must be signed by CEO, President, Chairman or Executive Director"
					labelAlignment="left"
					value={title}
					name="title"
					onChange={handleChange}
					className="mt-4 w-full md:w-1/2"
				/>
				<Input
					label="Signature (Please Type)"
					labelAlignment="left"
					value={signature}
					name="signature"
					onChange={handleChange}
					className="mt-4 w-full md:w-1/2"
				/>
				<Input
					label="Date"
					labelAlignment="left"
					value={date}
					name="date"
					onChange={handleChange}
					className="mt-4 w-1/2 md:w-1/4"
				/>
				<div className="flex justify-center mt-8">
					<Button
						isLoading={isLoading}
						onClick={handleSubmit}
						label="Submit"
						variant="brand"
					/>
				</div>
			</form>
		</div>
	);
}

export default FraudStatement;
