import { useContext, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
	VisualPicker,
	VisualPickerOption,
	VisualPickerOptionFooter,
	Button,
	ButtonIcon,
	Select,
	RadioButtonGroup,
} from '../components/ReactRainbow';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Header from '../components/Header';
import { AppContext } from '../context';

const theme = {
	rainbow: {
		palette: {
			brand: '#dc5648',
			success: '#dc5648',
		},
	},
};

const SelectDemo = ({ demos, getOfferings }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState('accident');

	const {
		appState: {
			demoType = null, offerings, selectedOffering, authData, longform, offerPlacement,
		},
		setAppState,
		displayError,
	} = useContext(AppContext);

	const setDemo = (demo) => setAppState({ demoType: demo });

	const handleContinue = () => {
		if (!demoType) {
			displayError('Please select a demo.');
		}
		navigate(demoType);
	};

	const updateSelectedProducts = (value) => {
		// left these as reg exp so if we need to add more products later
		if (value?.match(/COMMERCIAL/)) {
			setSelectedProduct('commercial');
			// this is to make sure that Commercial works even if a user moved offer around beforehand
			setAppState({ offerPlacement: 'after-info', longform: false });
		} else if (value?.match(/BUDDY_ACCIDENT_V2/)) {
			setSelectedProduct('accident');
		} else setSelectedProduct('ticket');
	};

	const handleSelectProduct = ({ target: { value } }) => {
		setAppState({ selectedOffering: value });
		updateSelectedProducts(value);
	};

	const handleFormTypeChange = () => {
		setAppState({
			longform: !longform,
			offerPlacement: 'after-info',
		});
	};

	const handleOfferPlacementChange = ({ target: { value } }) => {
		setAppState({ offerPlacement: value });
	};

	const handleRefreshProducts = async () => {
		setIsLoading(true);
		const token = authData?.signInUserSession?.idToken;
		await getOfferings(token);
		setIsLoading(false);
		setSelectedProduct('accident');
	};

	useDocumentTitle('Buddy Integration Demo');

	useEffect(() => {
		const currentFavicon = document.getElementById('favicon') || {};
		if (currentFavicon) {
			currentFavicon.href = '/assets/buddy_lettermark_R.svg';
		}
	}, []);

	useEffect(() => {
		updateSelectedProducts(selectedOffering);
	}, []);

	const commercialDemo = demos.filter((demo) => demo.demoType === 'commercial');
	const accidentDemos = demos.filter((demo) => demo.demoType === 'accident');
	const ticketDemos = demos.filter((demo) => demo.demoType === 'ticket');

	const marginFix = {
		marginLeft: '2.5rem',
		marginRight: '2.5rem',
		maxWidth: '12rem',
	};

	const footerStyle = {
		maxWidth: '9rem',
		textAlign: 'center',
	};

	return (
		<>
			<Header theme={theme} header="Integration Demo" />
			<h2 className="buddy-h2 color-black text-center my-4 px-4">
				Create Your Demo
			</h2>
			<div className="flex flex-col items-center justify-center my-5" data-testid="testing">
				<div className="flex items-center">
					<h3 className="buddy-h3">1. Select a Product</h3>
					<ButtonIcon
						className="ml-3"
						variant="neutral"
						icon={<FontAwesomeIcon icon={faSyncAlt} className={isLoading ? 'fast-spin' : ''} />}
						onClick={handleRefreshProducts}
						size="small"
						tooltip="Refresh"
						title="Refresh"
						type="button"
					/>
				</div>
				<Select
					options={offerings.map((el) => ({ label: el.id, value: el.id }))}
					onChange={handleSelectProduct}
					value={selectedOffering}
					label="Product Dropdown"
					hideLabel
				/>
				<h3 className="buddy-h3 mt-12">2. Select a Demo</h3>
			</div>
			<div className="flex justify-center mb-5">
				<VisualPicker
					id="visual-picker-component-5"
					hideLabel
					label="Select a demo type"
					value={demoType}
					onChange={setDemo}
				>
					{selectedProduct === 'commercial'
					&& commercialDemo.map(({
						path, demoImg, demoTitle, demoImgAlt,
					}) => (
						<VisualPickerOption
							name={path.replace('*', '')}
							key={path}
							className="mx-10"
							style={marginFix}
							footer={<VisualPickerOptionFooter label={demoTitle} />}
						>
							<img style={{ width: '70px' }} src={demoImg} alt={demoImgAlt} />
						</VisualPickerOption>
					))}
					{selectedProduct === 'accident'
					&& accidentDemos.map(({
						path, demoImg, demoTitle, demoImgAlt,
					}) => (
						<VisualPickerOption
							name={path.replace('*', '')}
							key={path}
							className="mx-10"
							style={marginFix}
							footer={<VisualPickerOptionFooter label={demoTitle} style={footerStyle} />}
						>
							<img style={{ width: '70px' }} src={demoImg} alt={demoImgAlt} />
						</VisualPickerOption>
					))}
					{selectedProduct === 'ticket'
					&& ticketDemos.map(({
						path, demoImg, demoTitle, demoImgAlt,
					}) => (
						<VisualPickerOption
							name={path.replace('*', '')}
							key={path}
							className="mx-10"
							style={marginFix}
							footer={<VisualPickerOptionFooter label={demoTitle} />}
						>
							<img style={{ width: '70px' }} src={demoImg} alt={demoImgAlt} />
						</VisualPickerOption>
					))}
				</VisualPicker>
			</div>
			{selectedProduct === 'accident' && (
				<div className="flex flex-col items-center justify-center my-5">
					<h3 className="buddy-h3 mb- ">3. Select a View</h3>
					<div className="flex justify-center ">
						<RadioButtonGroup
							label="Select a View"
							hideLabel
							options={[{ value: 'true', label: 'Long Form' }, { value: 'false', label: 'Step Through' }]}
							onChange={handleFormTypeChange}
							style={{ width: 260 }}
							value={longform?.toString()}
							className="mb-5"
							name="longform-toggle"
						/>
					</div>
					{!longform && (
						<div className="flex flex-col items-center justify-center my-5">
							<h3 className="buddy-h3 ">4. Offer Placement</h3>
							<div className="flex justify-center">
								<Select
									options={[{ label: 'Before Info Collection', value: 'before-info' }, { label: 'After Info Collection', value: 'after-info' }, { label: 'With Checkout', value: 'with-checkout' }]}
									onChange={handleOfferPlacementChange}
									value={offerPlacement}
									label="Offer Placement Selection"
									hideLabel
								/>
							</div>
						</div>
					)}
				</div>
			)}
			{selectedProduct === 'ticket' && (
				<div className="flex flex-col items-center justify-center my-5">
					<h3 className="buddy-h3 mb-5">3. Select a View</h3>
					<div className="flex justify-center">
						<RadioButtonGroup
							label="Select a View"
							hideLabel
							options={[{ value: 'true', label: 'Long Form' }, { value: 'false', label: 'Step Through' }]}
							onChange={handleFormTypeChange}
							style={{ width: 260 }}
							value={longform?.toString()}
							className="mb-5"
							name="longform-toggle"
						/>
					</div>
					{!longform && (
						<div className="flex flex-col items-center justify-center my-5">
							<h3 className="buddy-h3 ">4. Offer Placement</h3>
							<div className="flex justify-center">
								<Select
									options={[{ label: 'Before Info Collection', value: 'before-info' }, { label: 'After Info Collection', value: 'after-info' }, { label: 'With Checkout', value: 'with-checkout' }]}
									onChange={handleOfferPlacementChange}
									value={offerPlacement}
									label="Offer Placement Selection"
									hideLabel
								/>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="flex justify-center my-5">
				<Button onClick={handleContinue} label="Continue" />
			</div>
		</>
	);
};

export default SelectDemo;
