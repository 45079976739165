import React, { useContext } from 'react';

import { AppContext } from '../context';

const Thanks = () => {
	const {
		appState: { orderID, product, isPurchasing },
	} = useContext(AppContext);

	const enjoyText = `Enjoy your time ${
		product?.name === 'both' ? 'hunting and fishing' : product?.verb
	} safely.`;

	return (
		<div className="mx-auto mb-5 w-5/6">
			<div className="text-4xl text-center mb-4 px-4">Thanks!</div>
			<p className="text-2xl text-center">{enjoyText}</p>
			{!isPurchasing && (
				<p className="text-center w-1/2 mx-auto mt-4">
					And it's never too late to get protected. Click{' '}
					<a
						className="text-orange-500 underline"
						href="https://buddyinsurance.com"
					>
						{' '}
						here
					</a>{' '}
					if you've changed your mind.
				</p>
			)}
			{isPurchasing && (
				<p className="text-center w-1/2 mx-auto mt-4">
					Your Buddy Insurance order id is{' '}
					<span className="font-bold">{orderID}</span> and your policy should
					already be waiting for you in your email.
				</p>
			)}
		</div>
	);
};

export default Thanks;
