import React, { useContext } from 'react';
import { Input } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';
import YesNo from '../../components/YesNo';

function ActivityInfo() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		requiresWaiver,
		requiresHealthAndPhysicalForm,
		hasBrochureOrWebPage,
		yearsInBusiness,
		priorExperience,
		operatesOutsideUS,
		hiresSubContractors,
		subContractedActivities = '',
		doesObtainProofOfInsurance,
		isOperationalYearRound,
		numberOfOperationalMonths,
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART III - OPERATION INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				<YesNo
					label="Do you require guests to sign a liability waiver?"
					value={requiresWaiver}
					name="requiresWaiver"
					onChange={handleChange}
				/>
				<YesNo
					label="Do you require guests to complete a health & physical form?"
					value={requiresHealthAndPhysicalForm}
					name="requiresHealthAndPhysicalForm"
					onChange={handleChange}
				/>
				<YesNo
					label="Do you have a brochure or website?"
					value={hasBrochureOrWebPage}
					name="hasBrochureOrWebPage"
					onChange={handleChange}
				/>
				<label className="rainbow-label-lookalike pl-2 block mt-4">
					How many years have you been in business?
				</label>
				<Input
					className="w-1/2 md:w-1/6"
					type="number"
					value={yearsInBusiness}
					onChange={handleChange}
					name="yearsInBusiness"
				/>
				<label className="rainbow-label-lookalike pl-2 block mt-4">
					If you are a new venture, how many years of prior experience?
				</label>
				<Input
					className="w-1/2 md:w-1/6"
					type="number"
					value={priorExperience}
					onChange={handleChange}
					name="priorExperience"
				/>
				<YesNo
					label="Are there any operations conducted outside the US?"
					value={operatesOutsideUS}
					name="operatesOutsideUS"
					onChange={handleChange}
				/>
				<YesNo
					label="Do you hire guides as sub-contractors?"
					value={hiresSubContractors}
					name="hiresSubContractors"
					onChange={handleChange}
				/>
				<IF condition={hiresSubContractors === 'Yes'}>
					<label className="rainbow-label-lookalike pl-2 block mt-4">
						For which activities do you hire sub-contractors?
					</label>
					<Input
						className="w-1/2 md:w-1/6 mt-4"
						type="text"
						value={subContractedActivities}
						onChange={handleChange}
						name="subContractedActivities"
					/>
					<YesNo
						label="Do you obtain proof of insurance from sub-contractors?"
						value={doesObtainProofOfInsurance}
						name="doesObtainProofOfInsurance"
						onChange={handleChange}
					/>
				</IF>
				<YesNo
					label="Is your business operational year-round?"
					value={isOperationalYearRound}
					name="isOperationalYearRound"
					onChange={handleChange}
				/>
				<IF condition={isOperationalYearRound === 'No'}>
					<label className="rainbow-label-lookalike pl-2 block mt-4">
						How many months are you operation?
					</label>
					<Input
						className="w-1/2 md:w-1/6 mt-4"
						type="number"
						value={numberOfOperationalMonths}
						onChange={handleChange}
						name="numberOfOperationalMonths"
					/>
				</IF>
			</form>
		</div>
	);
}

export default ActivityInfo;
