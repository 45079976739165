import moment from 'moment-timezone';
import {
	SelectProduct,
	// Duration,
	BuddyInsurance,
	WhoAreYou,
	Checkout,
	Thanks,
} from '../views';

import { RacePricing } from '../models/Pricing';
import { whoAreYouErrorCheck, selectProductErrorCheck } from './utils';

const venue = { name: 'The Baum.com Race Series', state: 'AZ' };

const logo = '/assets/bomb.svg';

const products = [
	{
		name: 'Triathlon',
		verb: 'racing',
		imgPath: '/assets/triathlon.svg',
		description: 'Olympic (1.5km/40km/10km)',
		label: '$150',
		imgAlt: 'triathlon',
	},
	{
		name: 'Marathon',
		verb: 'racing',
		imgPath: '/assets/running.svg',
		description: '26.2 miles though the city',
		label: '$100',
	},
	{
		name: 'UltraMarathon',
		verb: 'racing',
		imgPath: '/assets/marathon.svg',
		description: '50 miles (mostly paved)',
		label: '$175',
	},
	{
		name: 'Road Cycle Race',
		verb: 'racing',
		imgPath: '/assets/cycling-race.svg',
		description: '150 miles of total fury,',
		label: '$125',
	},
	{
		name: 'MTB Race',
		verb: 'racing',
		imgPath: '/assets/mtb.svg',
		description: 'Downhill (Time Trial)',
		label: '$95',
	},
];

export default {
	demoImg: '/assets/race.svg',
	demoImgAlt: 'race',
	demoTitle: 'Race Series',
	demoType: 'accident',
	header: (
		<>
			The Baum.com Race Series
		</>
	),
	logo: '/assets/bomb.svg',
	path: 'baum-race-series/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#FDA057',
				success: '#FDA057',
			},
		},
	},
	views: [
		{
			component: (
				<SelectProduct
					products={products}
					heading="Select your event:"
					subHeading={`All events will be held on ${moment().add(90, 'days').format('MM/DD/YYYY')}`}
					logo={logo}
					title="The Baum Race Series"
				/>
			),
			path: 'select-product',
			default: true,
			errorCheck: selectProductErrorCheck,
		},
		// {
		// 	component: (
		// 		<Duration
		// 			dateOptions={[
		// 				{ value: 'oneday', label: '1 Day' },
		// 				{ value: 'range', label: 'Specific Date Range' },
		// 			]}
		// 		/>
		// 	),
		// 	path: 'select-duration',
		// 	errorCheck: () => ({ ok: true }),
		// },
		{
			component: <WhoAreYou />,
			path: 'customer-info',
			errorCheck: whoAreYouErrorCheck,
		},
		{
			component: <BuddyInsurance
				venue={venue}
				priceModel={RacePricing}
				titleOverRide="Go Bigger with Buddy!"
				helperCopy="Have fun on race day and don’t let the worry of an injury keep you from the podium!"
			/>,
			path: 'buddy-insurance',
			errorCheck: () => ({ ok: true }),
			className: 'bg-pale-yellow',
		},
		{
			component: <Checkout priceModel={RacePricing} venue={venue} />,
			path: 'checkout',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
