import {
	SelectProduct,
	Duration,
	BuddyInsurance,
	WhoAreYou,
	Checkout,
	Thanks,
} from '../views';

import { SkiPricing } from '../models/Pricing';
import { whoAreYouErrorCheck, selectProductErrorCheck } from './utils';

const venue = { name: 'Shawl Mountain', state: 'CO' };

const products = [
	{
		name: 'lift tickets',
		verb: 'skiing or snowboarding',
		imgPath: '/assets/ski-lift.svg',
	},
	{
		name: 'lodging',
		verb: 'staying at Shawl Resorts',
		imgPath: '/assets/suitcase.svg',
	},
	{
		name: 'lift and lodging',
		verb: 'skiing or snowboarding and staying at Shawl Resorts',
		imgPath: '/assets/ski_lodge.svg',
	},
];

export default {
	demoImg: '/assets/resort.svg',
	demoImgAlt: 'ski',
	demoTitle: 'Ski Resort',
	header: 'Shawl Resorts',
	demoType: 'accident',
	logo: '/assets/mtn_logo.svg',
	path: 'ski/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#144276',
				success: '#144276',
			},
		},
	},
	views: [
		{
			component: <SelectProduct products={products} logo="/assets/mtn_logo.svg" title="Shawl Resorts" />,
			path: 'select-product',
			default: true,
			errorCheck: selectProductErrorCheck,
		},
		{
			component: (
				<Duration
					titleOverride="How long do you plan on visiting us?"
					dateOptions={[
						{ value: 'oneday', label: '1 Day' },
						{ value: 'range', label: 'Specific Date Range' },
					]}
				/>
			),
			path: 'select-duration',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <WhoAreYou />,
			path: 'customer-info',
			errorCheck: whoAreYouErrorCheck,
		},
		{
			component: (
				<BuddyInsurance productOverride="on the mountain" venue={venue} priceModel={SkiPricing} />
			),
			path: 'buddy-insurance',
			errorCheck: () => ({ ok: true }),
			className: 'bg-pale-yellow',
		},
		{
			component: <Checkout priceModel={SkiPricing} venue={venue} />,
			path: 'checkout',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
