import {
	useState, useContext, useEffect, cloneElement,
} from 'react';
import { Router, Redirect } from '@reach/router';
import { Card, Spinner } from './ReactRainbow';
import BuddyAPI from '../models/BuddyAPI';
import MainApp from '../views/MainApp';
import { SelectDemo } from '../views';
import demos from '../demos';
import { AppContext } from '../context';

const Loading = () => (
	<div className="container flex justify-center items-center h-screen mx-auto">
		<div className="w-full p-3 sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2">
			<Card
				icon={(
					<img
						className="w-8"
						src="/assets/buddy_lettermark_R.svg"
						alt="logo"
					/>
				)}
				title="Loading..."
			>
				<div
					className="w-full flex justify-center items-center"
					style={{ minHeight: 381 }}
				>
					<Spinner variant="brand" />
				</div>
			</Card>
		</div>
	</div>
);

const Motumbo = () => {
	const {
		appState: { authData },
		setAppState,
		displayError,
	} = useContext(AppContext);

	const [isLoading, setIsLoading] = useState(true);

	const cognitoToken = authData?.signInUserSession?.idToken;

	const getOfferings = async (token) => {
		try {
			const offerings = await BuddyAPI.getOfferings(token?.jwtToken);
			setAppState({ offerings, selectedOffering: offerings?.length && offerings[0].id });
			setIsLoading(false);
		} catch (error) {
			displayError(
				`Retrieving data failed with error: "${
					error?.message || error
				}." If this issue persists, please contact Buddy's Dev Team.`,
				true,
			);
			await authData.signOut();
			setAppState({ authData: null });
		}
	};

	useEffect(() => {
		if (cognitoToken) {
			getOfferings(cognitoToken);
		}
	}, []);

	if (!cognitoToken) {
		return <Redirect to="/login" noThrow />;
	}

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Router>
			<SelectDemo path="/" getOfferings={getOfferings} demos={demos} />
			{demos.map((el) => cloneElement(<MainApp key={el.path} />, { ...el }))}
		</Router>
	);
};

export default Motumbo;
