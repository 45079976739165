import parser from 'parse-address';

export const selectProductErrorCheck = (state) => {
	if (state.product) {
		return { ok: true };
	}
	return { ok: false, error: 'Please select a product to continue.' };
};

export const whoAreYouErrorCheck = ({ customer }) => {
	const {
		name, email, address, dob,
	} = customer;

	const missed = [name, email, address, dob].filter((el) => {
		if (!el) {
			return true;
		}
		if (typeof el === 'string' && !el.length) {
			return true;
		}
		if (typeof el === 'object' && !Object.keys(el).length) {
			return true;
		}
		return false;
	});

	const parsedAddress = parser.parseInformalAddress(address);
	const {
		number, street, type, city, state, zip,
	} = parsedAddress;

	if (missed.length) {
		return { ok: false, error: 'Please fill in all required fields.' };
	}
	// if the address prop is not an object, this means that the user typed in manually and we're checking that they included everything
	if (typeof address !== 'object') {
		if (number === undefined || street === undefined || type === undefined || city === undefined || state === undefined || zip === undefined) {
			return { ok: false, error: 'Please enter a full address. (Required Format: Street Number, Street Name, Street Type, City, State, Zipcode)' };
		}
	}
	return { ok: true };
};
