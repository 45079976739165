import React, { useContext } from 'react';
import { CheckboxGroup } from '../../components/ReactRainbow';
import YesNo from '../../components/YesNo';
import IF from '../../components/IF';
import { AppContext } from '../../context';

const piiOptions = [
	{
		value: 'ssn',
		label: 'Social Security Numbers, Bank or Other Financial Account Details, Driver’s License or other State Identification Numbers',
	},
	{
		value: 'health-data',
		label: 'Non-public Medical or Healthcare Data, including Protected Health Information (PHI)',
	},
	{
		value: 'credit-card',
		label: 'Credit or Debit Card Information',
	},
];

function CyberSecurity() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		piiCollected = [],
		doesCollectPII,
		hasBeenAccusedOfDamagingSystem,
		hasBeenSued,
		hasBeenInvestigated,
		isAnticipatingClaim,
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART XII - CYBERSECURITY QUESTIONNAIRE
			</p>
			<form className="md:w-3/4 mx-auto mt-4">
				<YesNo
					label="Do you collect, store or otherwise handle any Personally Identifiable Information (PII) belonging to customers, clients, or other third parties, other than employees?"
					value={doesCollectPII}
					name="doesCollectPII"
					onChange={handleChange}
				/>
				<IF condition={doesCollectPII === 'Yes'}>
					<label className="rainbow-label-lookalike pl-2 block mt-4">
						Please indicate the types of Personally Identifiable Information held (check all that apply)
					</label>
					<CheckboxGroup
						options={piiOptions}
						value={piiCollected}
						onChange={(values) => setAppState({ piiCollected: values })}
					/>
				</IF>
				<YesNo
					label="During the last 3 years, has anyone alleged that you were responsible for damage to their computer system(s) arising out of the operation of the your computer system(s)?"
					value={hasBeenAccusedOfDamagingSystem}
					name="hasBeenAccusedOfDamagingSystem"
					onChange={handleChange}
				/>
				<YesNo
					label="During the last 3 years, has anyone made a demand, claim, complaint, or filed a lawsuit against you, alleging invasion or interference of rights of privacy or the inappropriate disclosure of Personally Identifiable Information (PII)?"
					value={hasBeenSued}
					name="hasBeenSued"
					onChange={handleChange}
				/>
				<YesNo
					label="During the last 3 years, have you been the subject of an investigation or action by any regulatory or administrative agency for privacy-related violations?"
					value={hasBeenInvestigated}
					name="hasBeenInvestigated"
					onChange={handleChange}
				/>
				<YesNo
					label="Are you aware of any circumstance that could reasonably be anticipated to result in a claim being made against you for the coverage being applied for?"
					value={isAnticipatingClaim}
					name="isAnticipatingClaim"
					onChange={handleChange}
				/>
			</form>
		</div>
	);
}

export default CyberSecurity;
