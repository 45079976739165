import {
	FakeLogin,
	Dashboard,
	Settings,
	GeneralInfo,
	ActivityInfo,
	OperationsInformation,
	GuideInfo,
	RetailInfo,
	BoatGeneralInfo,
	Boats,
	CanoesKayaks,
	LossHistory,
	// WinterProtection,
	// CyberSecurity,
	FraudStatement,
	Thanks,
} from '../views/commercial';

export default {
	demoImg: '/assets/resort.svg',
	demoTitle: 'Commercial',
	header: 'WoodBooker',
	logo: '/assets/yeti.svg',
	demoType: 'commercial',
	demoImgAlt: 'commercial',
	path: 'woodbooker/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#6ccdd6',
				success: '#144276',
			},
		},
	},
	views: [
		{
			component: <FakeLogin header="Operator Login" logo="/assets/yeti.svg" title="Woodbooker" />,
			path: 'sign-in',
			errorCheck: () => ({ ok: true }),
			hideFooter: true,
		},
		{
			component: <Dashboard title="Operator Home" />,
			path: 'dashboard',
			errorCheck: () => ({ ok: true }),
			hideFooter: true,
		},
		{
			component: <Settings title="Settings" />,
			path: 'settings',
			errorCheck: () => ({ ok: true }),
			hideFooter: true,
		},
		{
			component: <GeneralInfo />,
			path: 'general-info',
			errorCheck: () => ({ ok: true }),
			progress: 0.00001, // cheating here with that tiny value to basically show progress but keep it truthy.
		},
		{
			component: <ActivityInfo />,
			path: 'activity-info',
			errorCheck: () => ({ ok: true }),
			progress: 1 / 9,
		},
		{
			component: <OperationsInformation />,
			path: 'operations-info',
			errorCheck: () => ({ ok: true }),
			progress: 2 / 9,
		},
		{
			component: <GuideInfo />,
			path: 'guide-info',
			errorCheck: () => ({ ok: true }),
			progress: 3 / 9,
		},
		{
			component: <RetailInfo />,
			path: 'retail-info',
			errorCheck: () => ({ ok: true }),
			progress: 4 / 9,
		},
		{
			component: <BoatGeneralInfo />,
			path: 'boat-general-info',
			errorCheck: () => ({ ok: true }),
			progress: 5 / 9,
		},
		{
			component: <Boats />,
			path: 'watercraft-info',
			errorCheck: () => ({ ok: true }),
			progress: 6 / 9,
		},
		{
			component: <CanoesKayaks />,
			path: 'nonpowered-watercraft-info',
			errorCheck: () => ({ ok: true }),
			progress: 7 / 9,
		},
		{
			component: <LossHistory />,
			path: 'loss-history',
			errorCheck: () => ({ ok: true }),
			progress: 8 / 9,
		},
		// {
		// 	component: <WinterProtection />,
		// 	path: 'winter-protection',
		// 	errorCheck: () => ({ ok: true }),
		// 	progress: 0.9,
		// },
		// {
		// 	component: <CyberSecurity />,
		// 	path: 'cyber-security',
		// 	errorCheck: () => ({ ok: true }),
		// 	progress: 0.9,
		// },
		{
			component: <FraudStatement />,
			path: 'fraud-statement',
			errorCheck: () => ({ ok: true }),
			progress: 8.5 / 9,
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
