import React, { useState } from 'react';
import { Accordion, AccordionSection, Badge } from '../../components/ReactRainbow';

function Settings({ title, navigateNext }) {
	const [activeSectionNames, setActiveSectionNames] = useState(['products']);

	const marginFix = {
		marginLeft: 'auto',
		marginRight: 'auto',
	};

	return (
		<>
			<h2 className="text-2xl font-bold text-center mt-4">{title || 'Settings'}</h2>
			<Accordion
				className="border rounded md:w-3/4 mx-auto mt-4"
				activeSectionNames={activeSectionNames}
				onToggleSection={(event, sections) => setActiveSectionNames(sections)}
				multiple
				style={marginFix}
			>
				<AccordionSection label="Company Profile" />
				<AccordionSection label="Account Info" />
				<AccordionSection label="Admin Controls" />
				<AccordionSection label="Products" name="products">
					<div
						className="border rounded px-5 py-3 cursor-pointer"
						onClick={() => navigateNext()}
						tabIndex={0}
						role="button"
						onKeyDown={({ keyCode }) => {
							if (keyCode === 13) {
								navigateNext();
							}
						}}
					>
						<span className="font-bold text-lg">General Liability Insurance</span>
						<p className="mt-4">
							Protect your customers and your business with up to $1,000,000 in general liability coverage.
						</p>
						<div>
							<Badge
								label="Status: Not Started"
								variant="warning"
								className="mt-4"
							/>
						</div>
						<div className="flex flex-row justify-end text-xs">
							<span className="my-auto">Insurance offer powered by</span>
							<a
								href="https://buddyinsurance.com"
								target="_blank"
								rel="noopener noreferrer"
								style={{ backgroundColor: '#dc5648', borderColor: '#dc5648' }}
								className="flex flex-col font-bold text-sm ml-1 my-auto px-4  border rounded-full text-white hover:text-white hover:no-underline"
							>
								Buddy
							</a>
						</div>
					</div>
				</AccordionSection>
				<AccordionSection label="User Management" />
			</Accordion>
		</>
	);
}

export default Settings;
