import React, { useContext } from 'react';
import { Input } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';

const GuideComponent = ({
	name, experience, firstAid, onChange, index, remove,
}) => (
	<div className="border rounded p-8">
		<IF condition={index > 0}>
			<button
				type="button"
				className="float-right"
				onClick={() => remove(index)}
			>
				<div className="flex items-center text-red-500">
					<span>Remove</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</div>
			</button>
		</IF>
		<Input
			className="w-full md:w-1/2"
			label="Name"
			labelAlignment="left"
			placeholder=""
			type="text"
			value={name || ''}
			name="name"
			onChange={(event) => onChange(event, index)}
		/>
		<label className="rainbow-label-lookalike block mt-4">
			Years of Experience
		</label>
		<Input
			className="w-full md:w-1/6"
			placeholder=""
			type="number"
			value={experience || ''}
			name="experience"
			onChange={(event) => onChange(event, index)}
		/>
		<Input
			className="w-full md:w-1/2 mt-4"
			label="First Aid Qualifications"
			labelAlignment="left"
			placeholder="Level 3 First Aid"
			type="text"
			value={firstAid}
			name="firstAid"
			onChange={(event) => onChange(event, index)}
		/>
	</div>
);

const blankGuide = {
	name: '', experience: '', firstAid: '',
};

function GuideInfo() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		guides = [blankGuide],
	} = appState;

	const handleChangeGuide = ({ target: { name, value } }, index) => {
		const updatedField = { ...guides[index], [name]: value };
		const guidesArray = [...guides];
		guidesArray.splice(index, 1, updatedField);
		setAppState({ guides: guidesArray });
	};

	const addGuide = () => {
		const updatedFields = [...guides];
		updatedFields.push(blankGuide);
		setAppState({ guides: updatedFields });
	};

	const removeGuide = (index) => {
		const updatedFields = [...guides];
		updatedFields.splice(index, 1);
		setAppState({ guides: updatedFields });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART V - GUIDE INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				{ guides.map(({ name, experience, firstAid }, i) => (
					<GuideComponent
						name={name}
						experience={experience}
						firstAid={firstAid}
						index={i}
						// eslint-disable-next-line react/no-array-index-key
						key={`activity-${i}`}
						onChange={handleChangeGuide}
						remove={removeGuide}
					/>
				))}
				<div className="flex mt-4 justify-end">
					<button
						type="button"
						onClick={addGuide}
						className="focus:outline-none focus:text-underline hover:text-underline"
					>
						<div className="flex text-blue-400 items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>Add Guide</span>
						</div>
					</button>
				</div>
			</form>
		</div>
	);
}

export default GuideInfo;
