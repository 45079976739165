import {
	SelectProduct,
	Duration,
	BuddyInsurance,
	WhoAreYou,
	Checkout,
	Thanks,
} from '../views';

import { RadEntomologyPricing } from '../models/Pricing';
import { whoAreYouErrorCheck, selectProductErrorCheck } from './utils';

const venue = { name: 'Rad Entomology', state: 'AZ' };

const products = [
	{
		name: 'standard',
		verb: 'attending',
		label: 'Aphid Adventurer - $500',
		description: 'Food, Lodging, Standard Lectures, & the main event',
		imgPath: '/assets/beetle.svg',
	},
	{
		name: 'deluxe',
		verb: 'attending',
		label: 'Lord of the Flies - $700',
		description: 'Everything in standard, plus premium lectures, and VIP events',
		imgPath: '/assets/house-fly.svg',
	},
];

export default {
	path: 'rad-entomology/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#94a140',
				success: '#94a140',
			},
		},
	},
	header: 'Rad Entomology 2021',
	logo: '/assets/magnify-bug.svg',
	demoImg: '/assets/conference.svg',
	demoTitle: 'Conference',
	demoImgAlt: 'conference',
	demoType: 'ticket',
	views: [
		{
			component: (
				<SelectProduct
					products={products}
					heading="Select a conference package:"
					logo="/assets/magnify-bug.svg"
					title="Rad Entomology"
				/>
			),
			path: 'select-package',
			default: true,
			errorCheck: selectProductErrorCheck,
		},
		{
			component: <Duration titleOverride="When would you like to go?" />,
			path: 'select-duration',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <WhoAreYou />,
			path: 'customer-info',
			errorCheck: whoAreYouErrorCheck,
		},
		{
			component: <BuddyInsurance venue={venue} priceModel={RadEntomologyPricing} />,
			path: 'buddy-insurance',
			errorCheck: () => ({ ok: true }),
			className: 'bg-pale-yellow',
		},
		{
			component: (
				<Checkout
					productType="conference"
					priceModel={RadEntomologyPricing}
					venue={venue}
				/>
			),
			path: 'checkout',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
