import React, { useState, useContext, useEffect } from 'react';
import { formatToStandardDate, parseDate } from '@buddy-technology/buddy_helpers';
import MaskedInput from 'react-text-mask';
import {
	Input, CheckboxToggle, RadioGroup, DatePicker, RadioButtonGroup, TextArea, Select,
} from '../../components/ReactRainbow';
import IF from '../../components/IF';
import StateSelect from '../../components/StateSelect';

import { AppContext } from '../../context';

const businessTypes = [
	{ value: 'corporationCheck', label: 'Corporation' },
	{ value: 'partnershipCheck', label: 'Partnership' },
	{ value: 'individualCheck', label: 'Individual' },
	{ value: 'llcCheck', label: 'LLC' },
];

const otherBusinessTypes = [
	{ value: 'otherBusinessCorporationCheck', label: 'Corporation' },
	{ value: 'otherBusinessPartnershipCheck', label: 'Partnership' },
	{ value: 'otherBusinessIndividualCheck', label: 'Individual' },
	{ value: 'otherBusinessLLCCheck', label: 'LLC' },
];

const liabilityLimits = [
	{ value: 'limitOfLiability300000Check', label: '$300,000 Occurrence' },
	{ value: 'limitOfLiability500000', label: '$500,000 Occurrence' },
	{ value: 'limitOfLiability1000000', label: '$1,000,000 Occurrence' },
];

const defaults = {
	applicantName: 'Casey Baum',
	riskManagerEmail: 'casey@iambuddy.com',
	phone: '804-867-5309',
	locationStreetAddress: '1717 E Cary St',
	locationCity: 'Richmond',
	locationState: 'VA',
	locationZip: '23223',
	mailingStreetAddress: '1717 E Cary St',
	mailingCity: 'Richmond',
	mailingZip: '23223',
	mailingState: 'VA',
	website: 'https://buddyinsurance.com',
	effectiveDate: new Date(),
	businessType: 'corporationCheck',
};

const OtherSelect = ({
	onOtherInputChange,
	onChange,
	value,
	otherValue,
	otherName,
	options,
	otherCheckValueName,
	...rest
}) => (
	<>
		<Select
			value={value}
			onChange={onChange}
			options={options.concat([{
				value: otherCheckValueName || 'otherCheck',
				label: 'Other',
			}])}
			{...rest}
		/>
		<IF condition={value === otherName}>
			<label className="flex items-center rainbow-label-lookalike ml-2 mt-4">
				Specify Other:
			</label>
			<input
				className="w-full inline rainbow-input-lookalike md:w-1/2"
				name={otherName}
				type="text"
				value={otherValue}
				onChange={onOtherInputChange}
			/>

		</IF>
	</>
);

const BizTypeSelect = ({
	onChange, value, otherValue, id, onOtherInputChange, otherName, options, otherCheckValueName,
}) => (
	<OtherSelect
		label="Business Type"
		labelAlignment="left"
		className="mt-4 md:w-1/4"
		required
		id={id}
		value={value}
		name="businessType"
		onChange={onChange}
		otherValue={otherValue}
		otherName={otherName}
		options={options || businessTypes}
		onOtherInputChange={onOtherInputChange}
		otherCheckValueName={otherCheckValueName}
	/>
);

const AdditionalBusiness = ({
	onChange, bizValue, descriptionValue, otherValue, otherName, id = 'additional-business', onOtherInputChange, otherCheckValueName,
}) => (
	<div className="border rounded p-4 mt-4">
		<p className="my-4 text-sm">Other business</p>
		<BizTypeSelect
			onChange={onChange}
			value={bizValue}
			otherValue={otherValue}
			onOtherInputChange={onOtherInputChange}
			options={otherBusinessTypes}
			otherCheckValueName={otherCheckValueName}
			id={id}
			otherName={otherName}
		/>
		<TextArea
			onChange={onChange}
			value={descriptionValue}
			name="description"
			label="Description of business"
			labelAlignment="left"
			className="mt-4"
		/>
	</div>
);

const AddressGroup = ({
	required,
	addressValue,
	addressName = 'address',
	addressLabel = 'Address',
	cityValue,
	cityLabel = 'City',
	cityName = 'city',
	zipValue,
	zipLabel = 'Zip',
	zipName = 'zip',
	stateValue,
	stateLabel = 'State',
	stateName = 'state',
	onChange,
}) => (
	<>
		<Input
			className="w-full md:w-1/2 mt-4"
			label={addressLabel}
			labelAlignment="left"
			onChange={onChange}
			value={addressValue}
			placeholder="3734 Elvis Presley Blvd"
			required={required}
			autoComplete="street-address"
			name={addressName}
		/>
		<div className="flex mt-4">
			<Input
				className="w-full md:w-1/2 mr-2"
				label={cityLabel}
				labelAlignment="left"
				onChange={onChange}
				value={cityValue}
				placeholder="Memphis"
				required={required}
				autoComplete="city"
				name={cityName}
			/>
			<StateSelect
				required={required}
				label={stateLabel}
				name={stateName}
				value={stateValue}
				onChange={onChange}
			/>
		</div>
		<Input
			className="w-1/2 md:w-1/4 mt-4"
			label={zipLabel}
			labelAlignment="left"
			onChange={onChange}
			value={zipValue}
			placeholder="38116"
			required={required}
			autoComplete="postal-code"
			name={zipName}
		/>
	</>
);

const GeneralInfo = () => {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const [hasDifferentMailingAddress, setHasDifferentMailingAddress] = useState(false);

	const {
		applicantName,
		riskManagerEmail = 'vogey@iambuddy.com',
		phone = '804-867-5309',
		locationStreetAddress = '1717 E Cary St',
		locationCity = 'Richmond',
		locationState = 'VA',
		locationZip = '23223',
		mailingStreetAddress = '1717 E Cary St',
		mailingCity = 'Richmond',
		mailingZip = '23223',
		mailingState = 'VA',
		website = 'https://buddyinsurance.com',
		effectiveDate = new Date(),
		liabilityLimit,
		businessType = 'corporationCheck',
		otherBusinessType,
		businessTypeOther,
		hasOtherBusiness,
		otherBusinessDescription,
		otherBusinessTypeField,
	} = appState;

	// TODO: FIX THIS! Not how we want to do this.
	useEffect(() => {
		setAppState(defaults);
	}, []);

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto">
				Protect your customers and your business with up to $1,000,000 in general liability coverage from Buddy.
			</p>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART I - GENERAL INFORMATION
			</p>
			<form autoComplete="on" className="md:w-3/4 mx-auto">
				<Input
					className="w-full md:w-1/2"
					label="Applicant Name"
					labelAlignment="left"
					placeholder="Elvis Presley"
					type="text"
					value={applicantName}
					name="applicantName"
					required
					onChange={handleChange}
					autoComplete="name"
				/>
				<AddressGroup
					required
					addressValue={locationStreetAddress}
					cityValue={locationCity}
					zipValue={locationZip}
					stateValue={locationState}
					addressName="locationStreetAddress"
					cityName="locationCity"
					zipName="locationZip"
					stateName="locationState"
					onChange={handleChange}
				/>
				<CheckboxToggle
					onChange={() => setHasDifferentMailingAddress((prev) => !prev)}
					value={hasDifferentMailingAddress}
					label="Use a different mailing address?"
					labelAlignment="left"
					className="mt-4"
				/>
				<IF condition={hasDifferentMailingAddress}>
					<AddressGroup
						required
						addressValue={mailingStreetAddress}
						cityValue={mailingCity}
						zipValue={mailingZip}
						stateValue={mailingState}
						onChange={handleChange}
						addressLabel="Mailing Address"
						addressName="mailingStreetAddress"
						cityName="mailingCity"
						stateName="mailingState"
					/>
				</IF>
				<div className="w-full md:w-1/2 mt-4">
					<label className="rainbow-label-lookalike" htmlFor="dob">
						<abbr title="required" className="rainbow-asterisk">
							*
							{' '}
						</abbr>
						Phone:
					</label>
					<MaskedInput
						mask={[
							'(',
							/[2-9]/,
							/\d/,
							/\d/,
							')',
							' ',
							/\d/,
							/\d/,
							/\d/,
							'-',
							/\d/,
							/\d/,
							/\d/,
							/\d/,
						]}
						id="phone"
						name="phone"
						type="tel"
						guide={false}
						value={phone}
						className="rainbow-input-lookalike"
						placeholder="(555) 867-5309"
						onChange={handleChange}
						aria-describedby="phoneHelp"
						required
						autoComplete="tel"
					/>
				</div>
				<Input
					className="w-full md:w-1/2 mt-4"
					label="Email"
					name="riskManagerEmail"
					labelAlignment="left"
					placeholder="elvis@presley.com"
					type="email"
					value={riskManagerEmail}
					required
					onChange={handleChange}
					autoComplete="email"
				/>
				<Input
					className="w-full md:w-1/2 mt-4"
					label="Website"
					name="website"
					labelAlignment="left"
					placeholder="www.elvispresley.com"
					type="text"
					value={website}
					required
					onChange={handleChange}
					autoComplete="email"
				/>
				<BizTypeSelect
					value={businessType}
					onChange={handleChange}
					onOtherInputChange={({ target }) => setAppState({ businessTypeOther: target.value })}
					otherValue={businessTypeOther}
					otherName="businessTypeOther"
				/>
				<DatePicker
					label="Effective Date"
					labelAlignment="left"
					className="w-full md:w-1/2 mt-4"
					required
					value={parseDate(effectiveDate)}
					onChange={(value) => setAppState({ effectiveDate: formatToStandardDate(value) })}
					name="effectiveDate"
				/>
				<RadioGroup
					label="Limit of Liability Requested"
					className="mt-4"
					options={liabilityLimits}
					value={liabilityLimit}
					onChange={handleChange}
					name="liabilityLimit"
					id="liability-buttons"
					required
				/>
				<label className="mt-4 rainbow-label-lookalike pl-2 block">
					<abbr title="required" className="rainbow-asterisk">
						*
						{' '}
					</abbr>
					Do you operate any other business from this location?
				</label>
				<RadioButtonGroup
					className="md:w-1/6"
					options={[{ value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }]}
					value={hasOtherBusiness}
					onChange={handleChange}
					name="hasOtherBusiness"
					variant="brand"
					hideLabel
				/>
				<IF condition={hasOtherBusiness === 'Yes'}>
					<AdditionalBusiness
						bizValue={otherBusinessType}
						descriptionValue={otherBusinessDescription}
						onChange={handleChange}
						onOtherInputChange={handleChange}
						otherName="otherBusinessTypeField"
						otherValue={otherBusinessTypeField}
						otherCheckValueName="otherBusinessOther"
					/>
				</IF>
			</form>
		</div>
	);
};

export default GeneralInfo;
