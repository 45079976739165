import React, { useContext } from 'react';
import { Input, CheckboxGroup } from '../../components/ReactRainbow';
import { AppContext } from '../../context';

const retailOptions = [
	{ value: 'retailOperationsHasGeneralStoreCheck', label: 'General Store' },
	{ value: 'retailOperationsLiquorStoreCheck', label: 'Liquor Store' },
	{ value: 'retailOperationsGunSalesCheck', label: 'Gun Sales' },
	{ value: 'retailOperationsSkiEquipmentSalesCheck', label: 'Ski Equipment Sales' },
	{ value: 'retailOperationsSkiEquipmentRentalCheck', label: 'Ski Equipment Rental' },
	{ value: 'retailOperationsFishingEquipmentSalesCheck', label: 'Fishing Equipment Sales' },
	{ value: 'retailOperationsFishingEquipmentRentalCheck', label: 'Fishing Equipment Rental' },
	{ value: 'retailOperationsRestaurantCheck', label: 'Restaurant' },
];

function RetailInfo() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		retailOperations = [],
		grossRetailSales = '',
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART VI - RETAIL INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				<CheckboxGroup
					label="Do you have retail operations for any of the following?"
					labelAlignment="left"
					onChange={(values) => setAppState({ retailOperations: values })}
					options={retailOptions}
					value={retailOperations}
					className="mt-4"
				/>
				<label className="rainbow-label-lookalike block mt-4">
					Annual gross sales from retail operations
				</label>
				<Input
					className="md:w-1/6"
					value={grossRetailSales}
					onChange={handleChange}
					name="grossRetailSales"
				/>
			</form>
		</div>
	);
}

export default RetailInfo;
