import React, { useContext } from 'react';
import { properCase, unhyphenate } from '@buddy-technology/buddy_helpers';
import { Input, Select } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';

const BoatComponent = ({
	boatInfo, onChange, index, remove,
}) => {
	const {
		type, maxUsed, avgUsed,
	} = boatInfo;

	return (
		<div className="border rounded py-4 px-8">
			<IF condition={index > 0}>
				<button
					type="button"
					className="float-right"
					onClick={() => remove(index)}
				>
					<div className="flex items-center text-red-500">
						<span>Remove</span>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</div>
				</button>
			</IF>
			<Select
				labelAlignment="left"
				label="Boat Type"
				className="mt-4 md:w-1/6"
				options={['canoes', 'kayaks', 'tubes', 'rafts', 'standup-paddle-boards']
					.map((el) => ({ label: properCase(unhyphenate(el)), value: el }))}
				value={type}
				onChange={(event) => onChange(event, index)}
				name="type"
			/>
			<Input
				className="w-full md:w-1/6 mt-4"
				label="Maximum Used"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={maxUsed || ''}
				name="maxUsed"
				onChange={(event) => onChange(event, index)}
			/>
			<Input
				className="w-full md:w-1/6 mt-4"
				label="Average Used"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={avgUsed || ''}
				name="avgUsed"
				onChange={(event) => onChange(event, index)}
			/>
		</div>
	);
};

const blankBoat = {
	type: 'canoes',
	maxUsed: '',
	avgUsed: '',
};

function Boats() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		boats = [blankBoat],
		percentageOfWatercraftOperationsUnguided = '',
		numberOfWatercraftGuides = '',
	} = appState;

	const handleChangeBoat = ({ target: { name, value } }, index) => {
		const updatedField = { ...boats[index], [name]: value };
		const boatsArray = [...boats];
		boatsArray.splice(index, 1, updatedField);
		setAppState({ boats: boatsArray });
	};

	const addBoat = () => {
		const updatedActivities = [...boats];
		updatedActivities.push(blankBoat);
		setAppState({ boats: updatedActivities });
	};

	const removeBoat = (index) => {
		const updatedActivities = [...boats];
		updatedActivities.splice(index, 1);
		setAppState({ boats: updatedActivities });
	};

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART IX - NON-POWERED WATERCRAFT INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				{ boats.map((boat, i) => (
					<BoatComponent
						boatInfo={boat}
						index={i}
						// eslint-disable-next-line react/no-array-index-key
						key={`activity-${i}`}
						onChange={handleChangeBoat}
						remove={removeBoat}
					/>
				))}
				<div className="flex mt-4 justify-end">
					<button
						type="button"
						onClick={addBoat}
						className="focus:outline-none focus:text-underline hover:text-underline"
					>
						<div className="flex text-blue-400 items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>Add a Boat Type</span>
						</div>
					</button>
				</div>
				<label className="rainbow-label-lookalike block mt-4">
					What percentage of operations are unguided?
				</label>
				<Input
					className="w-full md:w-1/6 mt-4"
					placeholder=""
					type="text"
					value={percentageOfWatercraftOperationsUnguided || ''}
					name="percentageOfWatercraftOperationsUnguided"
					onChange={handleChange}
				/>
				<label className="rainbow-label-lookalike block mt-4">
					Number of Guides
				</label>
				<Input
					className="w-full md:w-1/6 mt-4"
					placeholder=""
					type="text"
					value={numberOfWatercraftGuides || ''}
					name="numberOfWatercraftGuides"
					onChange={handleChange}
				/>
			</form>
		</div>
	);
}

export default Boats;
