import {
	SelectProduct,
	Duration,
	BuddyInsurance,
	WhoAreYou,
	Checkout,
	Thanks,
} from '../views';

import { WildlifePricing } from '../models/Pricing';
import { whoAreYouErrorCheck, selectProductErrorCheck } from './utils';

const venue = { name: 'Wildlife Department', state: 'AZ' };

const products = [
	{
		name: 'hunting',
		verb: 'hunting',
		label: 'Man vs. Beast',
		description: 'Bullet, arrow, or your bare hands',
		imgPath: '/assets/hunting.svg',
	},
	{
		name: 'fishing',
		verb: 'fishing',
		label: 'Man vs. Fish',
		description: 'You should see the one that got away',
		imgPath: '/assets/fishing.svg',
	},
	{
		name: 'both',
		verb: 'hunting and fishing',
		label: 'Man vs. Vacation Days',
		description: 'Ever heard of a grocery store?',
		imgPath: '/assets/both.svg',
	},
];

export default {
	path: 'wildlife/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#25512D',
				success: '#25512D',
			},
		},
	},
	header: 'Dept. of Wildlife Resources',
	logo: '/assets/wildlife.svg',
	demoImg: '/assets/both.svg',
	demoTitle: 'Hunting/Fishing Licenses',
	demoType: 'accident',
	demoImgAlt: 'hunting',
	views: [
		{
			component: (
				<SelectProduct
					products={products}
					heading="What kind of license are you looking for?"
					logo="/assets/wildlife.svg"
					title="Dept. of Wildlife Resources"
				/>
			),
			path: 'select-license',
			default: true,
			errorCheck: selectProductErrorCheck,
		},
		{
			component: <Duration />,
			path: 'select-duration',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <WhoAreYou />,
			path: 'customer-info',
			errorCheck: whoAreYouErrorCheck,
		},
		{
			component: <BuddyInsurance venue={venue} priceModel={WildlifePricing} />,
			path: 'buddy-insurance',
			errorCheck: () => ({ ok: true }),
			className: 'bg-pale-yellow',
		},
		{
			component: (
				<Checkout
					productType="license"
					priceModel={WildlifePricing}
					venue={venue}
				/>
			),
			path: 'checkout',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
