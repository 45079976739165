import { useEffect, useRef } from 'react';

// this hook fires an update only when a value changes, and not on initial render.
const useDidUpdateEffect = (func, deps) => {
	const didMount = useRef(false);
	useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
	}, deps);
};

export default useDidUpdateEffect;
