import {
	SelectProduct,
	Duration,
	BuddyInsurance,
	WhoAreYou,
	Checkout,
	Thanks,
} from '../views';

import { TourPricing } from '../models/Pricing';
import { whoAreYouErrorCheck, selectProductErrorCheck } from './utils';

const venue = { name: "Uncle Roy's Adventures", state: 'AZ' };

const products = [
	{
		name: 'ATV',
		verb: 'riding ATVs',
		imgPath: '/assets/atv.svg',
	},
	{
		name: 'Rafting',
		verb: 'whitewater rafting',
		imgPath: '/assets/rafting.svg',
	},
	{
		name: 'Guided Hike',
		verb: 'hiking',
		imgPath: '/assets/hiker.svg',
	},
];

export default {
	demoImg: '/assets/tour.svg',
	demoTitle: 'Multi',
	demoType: 'accident',
	demoImgAlt: 'tours',
	header: (
		<>
			Uncle Roy's Adventures
			{' '}
			<span style={{ fontSize: 16 }}>powered by Ticketbay</span>
		</>
	),
	logo: '/assets/pirate.svg',
	path: 'ticketbay/*',
	theme: {
		rainbow: {
			palette: {
				brand: '#144276',
				success: '#144276',
			},
		},
	},
	views: [
		{
			component: (
				<SelectProduct
					products={products}
					heading="Choose your adventure"
					logo="/assets/pirate.svg"
					title="Uncle Roy's Adventures"
				/>
			),
			path: 'select-product',
			default: true,
			errorCheck: selectProductErrorCheck,
		},
		{
			component: (
				<Duration
					dateOptions={[
						{ value: 'oneday', label: '1 Day' },
						{ value: 'range', label: 'Specific Date Range' },
					]}
				/>
			),
			path: 'select-duration',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <WhoAreYou />,
			path: 'customer-info',
			errorCheck: whoAreYouErrorCheck,
		},
		{
			component: <BuddyInsurance venue={venue} priceModel={TourPricing} />,
			path: 'buddy-insurance',
			errorCheck: () => ({ ok: true }),
			className: 'bg-pale-yellow',
		},
		{
			component: <Checkout priceModel={TourPricing} venue={venue} />,
			path: 'checkout',
			errorCheck: () => ({ ok: true }),
		},
		{
			component: <Thanks />,
			path: 'thanks',
		},
	],
};
