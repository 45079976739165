import React, { useContext } from 'react';
import { Input, Select } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';
import YesNo from '../../components/YesNo';

const MotorBoatComponent = ({
	boatInfo, onChange, index, remove,
}) => {
	const {
		year, makeAndModel, length, hp, engineType, numOfPassengers, isGuided,
	} = boatInfo;

	return (
		<div className="border rounded py-4 px-8">
			<IF condition={index > 0}>
				<button
					type="button"
					className="float-right"
					onClick={() => remove(index)}
				>
					<div className="flex items-center text-red-500">
						<span>Remove</span>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</div>
				</button>
			</IF>
			<Input
				className="w-full md:w-1/6 mt-4"
				label="Year"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={year || ''}
				name="year"
				onChange={(event) => onChange(event, index)}
			/>
			<Input
				className="w-full md:w-1/2 mt-4"
				label="Make & Model"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={makeAndModel || ''}
				name="makeAndModel"
				onChange={(event) => onChange(event, index)}
			/>
			<Input
				className="w-full md:w-1/6 mt-4"
				label="Length"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={length || ''}
				name="length"
				onChange={(event) => onChange(event, index)}
			/>
			<Input
				className="w-full md:w-1/6 mt-4"
				label="HP"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={hp || ''}
				name="hp"
				onChange={(event) => onChange(event, index)}
			/>
			<Select
				labelAlignment="left"
				label="Engine Type"
				className="mt-4 md:w-1/6"
				options={['OB', 'IB', 'IO'].map((el) => ({ label: el, value: el }))}
				value={engineType}
				onChange={(event) => onChange(event, index)}
				name="engineType"
			/>
			<Input
				className="w-full md:w-1/4 mt-4"
				label="Number of Passengers"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={numOfPassengers || ''}
				name="numOfPassengers"
				onChange={(event) => onChange(event, index)}
			/>
			<YesNo
				label="Guided?"
				value={isGuided}
				name="isGuided"
				onChange={(event) => onChange(event, index)}
			/>
		</div>
	);
};

const blankMotorBoat = {
	year: '',
	makeAndModel: '',
	length: '',
	hp: '',
	engineType: 'OB',
	numOfPassengers: '',
	isGuided: '',
};

function Boats() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		motorBoats = [blankMotorBoat],
	} = appState;

	const handleChangeMotorBoat = ({ target: { name, value } }, index) => {
		const updatedField = { ...motorBoats[index], [name]: value };
		const motorBoatsArray = [...motorBoats];
		motorBoatsArray.splice(index, 1, updatedField);
		setAppState({ motorBoats: motorBoatsArray });
	};

	const addMotorBoat = () => {
		const updatedActivities = [...motorBoats];
		updatedActivities.push(blankMotorBoat);
		setAppState({ motorBoats: updatedActivities });
	};

	const removeMotorBoat = (index) => {
		const updatedActivities = [...motorBoats];
		updatedActivities.splice(index, 1);
		setAppState({ motorBoats: updatedActivities });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART VIII - WATERCRAFT INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				<div className="my-4 border-t border-gray-300" />
				<p className="my-4 font-bold">
					Boat Schedule
				</p>
				{ motorBoats.map((boat, i) => (
					<MotorBoatComponent
						boatInfo={boat}
						index={i}
						// eslint-disable-next-line react/no-array-index-key
						key={`activity-${i}`}
						onChange={handleChangeMotorBoat}
						remove={removeMotorBoat}
					/>
				))}
				<div className="flex mt-4 justify-end">
					<button
						type="button"
						onClick={addMotorBoat}
						className="focus:outline-none focus:text-underline hover:text-underline"
					>
						<div className="flex text-blue-400 items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>Add Watercraft</span>
						</div>
					</button>
				</div>
			</form>
		</div>
	);
}

export default Boats;
