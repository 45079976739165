import { React, useEffect } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
	Input,
	Button,
} from '../../components/ReactRainbow';

const FakeLogin = ({
	navigateNext, title, logo, header,
}) => {
	useEffect(() => {
		const currentFavicon = document.getElementById('favicon') || {};
		currentFavicon.href = logo;
	}, []);
	useDocumentTitle(`${title}`);
	return (
		<>
			<div className="w-full sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2 mx-auto">
				<h2 className="text-2xl font-bold text-center mt-4">{header || 'Login'}</h2>
				<form className="p-3 sm:p-8 w-full md:w-11/12 lg:w-5/6 xl:w-4/5 mx-auto">
					<Input
						label="Email"
						placeholder="username@example.com"
						type="text"
						autoComplete="username"
						labelAlignment="left"
						name="username"
					/>
					<Input
						className="mt-4"
						label="Password"
						placeholder="********"
						type="password"
						autoComplete="current-password"
						labelAlignment="left"
						name="password"
					/>
					<Button
						className="link mt-4 block ml-auto"
						variant="base"
						label="Forgot Password?"
					/>
					<Button
						type="button"
						variant="brand"
						label="Sign In"
						style={{ display: 'block' }}
						className="mt-4 w-full"
						onClick={navigateNext}
					/>
				</form>
			</div>
		</>
	);
};

export default FakeLogin;
