import { Router } from '@reach/router';
import Amplify from 'aws-amplify';
import { Application } from './components/ReactRainbow';
import { AppProvider } from './context';
import Motumbo from './components/Motumbo';
import { Login, ForgotPassword } from './views';
// eslint-disable-next-line no-unused-vars
import MathExtensions from './models/MathExtension'; // leave this here!

const config = {
	aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
	aws_user_pools_web_client_id:
		process.env.REACT_APP_aws_user_pools_web_client_id,
};

Amplify.configure(config);

const theme = {
	rainbow: {
		palette: {
			brand: '#dc5648',
			success: '#c6d94b',
		},
	},
};

function App() {
	return (
		<AppProvider>
			<div className="flex flex-col items-center w-full">
				<Application className="w-full" theme={theme}>
					<Router>
						<Login path="/login" />
						<ForgotPassword path="/forgot-password" />
						<Motumbo path="/*" />
					</Router>
				</Application>
			</div>
		</AppProvider>
	);
}

export default App;
