import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context';
import useDocumentTitle from '../hooks/useDocumentTitle';
import {
	VisualPicker,
	VisualPickerOption,
	VisualPickerOptionFooter,
} from '../components/ReactRainbow';

const SelectProduct = ({
	products, heading, subHeading, logo, title,
}) => {
	const {
		appState: { product = null },
		setAppState,
	} = useContext(AppContext);
	// Selected product state
	const [selectedProduct, setProduct] = useState(product);

	const handleProductSelect = (productName) => {
		const prodObj = products.find(({ name }) => productName === name);
		setAppState({ product: prodObj });
		setProduct(prodObj);
	};

	useDocumentTitle(`${title}`);

	useEffect(() => {
		const currentFavicon = document.getElementById('favicon') || {};
		currentFavicon.href = logo;
	}, []);

	return (
		<div className="mx-auto">
			<h1 className="text-4xl text-center mb-4 px-4">
				{heading || 'Select a product:'}
			</h1>
			{subHeading ? (
				<h1 className="text-2xl text-center mb-4 px-4">
					{subHeading}
				</h1>
			) : null}
			<VisualPicker
				id="visual-picker-component-5"
				hideLabel
				value={selectedProduct?.name}
				onChange={handleProductSelect}
				className="w-full"
			>
				{products.map(({
					name, label, description = null, imgPath,
				}) => (
					<VisualPickerOption
						name={name}
						key={name}
						className="p-3"
						style={{ maxWidth: '12rem' }}
						footer={(
							<VisualPickerOptionFooter
								label={label}
								description={description}
							/>
						)}
					>
						<div className="text-lg capitalize">{name}</div>
						<img style={{ width: '70px' }} src={imgPath} alt={name} />
					</VisualPickerOption>
				))}
			</VisualPicker>
		</div>
	);
};

export default SelectProduct;
