import React, { useContext } from 'react';
import { Input, TextArea } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';
import YesNo from '../../components/YesNo';

const LossComponent = ({
	date, description, amountPaid, onChange, index, remove,
}) => (
	<div className="border rounded py-4 px-8">
		<IF condition={index > 0}>
			<button
				type="button"
				className="float-right"
				onClick={() => remove(index)}
			>
				<div className="flex items-center text-red-500">
					<span>Remove</span>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</div>
			</button>
		</IF>
		<Input
			className="w-full md:w-1/4 mt-4"
			label="Date"
			labelAlignment="left"
			type="text"
			value={date || ''}
			name="date"
			onChange={(event) => onChange(event, index)}
		/>
		<TextArea
			className="mt-4"
			label="Description"
			labelAlignment="left"
			value={description || ''}
			name="description"
			onChange={(event) => onChange(event, index)}
		/>
		<Input
			className="w-full md:w-1/4 mt-4"
			label="Amount Paid/Reserved"
			labelAlignment="left"
			value={amountPaid || ''}
			name="amountPaid"
			onChange={(event) => onChange(event, index)}
		/>
	</div>
);

const blankLoss = {
	date: '',
	description: '',
	amountPaid: '',
};

function LossHistory() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		losses = [blankLoss],
		knowsAboutPossibleClaimIncident,
		possibleClaimIncidentDescription,
	} = appState;

	const handleChangeLoss = ({ target: { name, value } }, index) => {
		const updatedField = { ...losses[index], [name]: value };
		const lossesArray = [...losses];
		lossesArray.splice(index, 1, updatedField);
		setAppState({ losses: lossesArray });
	};

	const addLoss = () => {
		const updatedLosses = [...losses];
		updatedLosses.push(blankLoss);
		setAppState({ losses: updatedLosses });
	};

	const removeLoss = (index) => {
		const updatedLosses = [...losses];
		updatedLosses.splice(index, 1);
		setAppState({ losses: updatedLosses });
	};

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART X - LOSS HISTORY
			</p>
			<form className="md:w-3/4 mx-auto">
				{ losses.map(({ date, description, amountPaid }, i) => (
					<LossComponent
						date={date}
						description={description}
						amountPaid={amountPaid}
						index={i}
						// eslint-disable-next-line react/no-array-index-key
						key={`activity-${i}`}
						onChange={handleChangeLoss}
						remove={removeLoss}
					/>
				))}
				<div className="flex mt-4 justify-end">
					<button
						type="button"
						onClick={addLoss}
						className="focus:outline-none focus:text-underline hover:text-underline"
					>
						<div className="flex text-blue-400 items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>Add a loss</span>
						</div>
					</button>
				</div>
				<YesNo
					label="Do you have knowledge of any incident which may lead to a claim?"
					value={knowsAboutPossibleClaimIncident}
					name="knowsAboutPossibleClaimIncident"
					onChange={handleChange}
				/>
				<IF condition={knowsAboutPossibleClaimIncident === 'Yes'}>
					<label className="rainbow-label-lookalike block mt-4">
						Please describe:
					</label>
					<TextArea
						className="mt-4"
						value={possibleClaimIncidentDescription || ''}
						name="possibleClaimIncidentDescription"
						onChange={handleChange}
					/>
				</IF>
			</form>
		</div>
	);
}

export default LossHistory;
