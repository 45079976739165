// this is just made up costs so it feels real

export const WildlifePricing = {
	calculateCost: ({ days, type }) => {
		if (!days || !type) {
			return null;
		}
		const min = 14.99;
		const dayRate = 0.87;
		const bothExtra = 0.07;
		if (days === 1) {
			return min + (type === 'both' ? 2 : 0);
		}
		const rate = dayRate + (type === 'both' ? bothExtra : 0);
		return Math.round(100 * (min + rate * days)) / 100;
	},
};

export const RadEntomologyPricing = {
	calculateCost: ({ type }) => {
		if (!type) {
			return null;
		}
		const prices = {
			standard: 500,
			deluxe: 700,
		};
		const rate = prices[type];
		return rate;
	},
};

export const SkiPricing = {
	calculateCost: ({ days, type }) => {
		if (!days || !type) {
			return null;
		}
		const prices = {
			lodging: {
				day: 190,
				weeK: 165,
			},
			'lift tickets': {
				day: 70,
				week: 50,
			},
			'lift and lodging': {
				day: 235,
				week: 205,
			},
		};
		const rate = prices[type][days < 7 ? 'day' : 'week'];
		return Math.round(100 * (rate * days)) / 100;
	},
};

export const TourPricing = {
	calculateCost: ({ days, type }) => {
		if (!days || !type) {
			return null;
		}
		let rate;
		if (type.toLowerCase() === 'atv') {
			rate = 130;
		} else if (type.toLowerCase() === 'rafting') {
			rate = 80;
		} else if (type.toLowerCase() === 'guided hike') {
			rate = 50;
		}
		return Math.round(100 * (rate * days)) / 100;
	},
};

export const RacePricing = {
	calculateCost: ({ type }) => {
		if (!type) {
			return null;
		}
		let rate;
		if (type.toLowerCase() === 'triathlon') {
			rate = 150;
		} else if (type.toLowerCase() === 'marathon') {
			rate = 100;
		} else if (type.toLowerCase() === 'ultramarathon') {
			rate = 175;
		} else if (type.toLowerCase() === 'road cycle race') {
			rate = 125;
		} else if (type.toLowerCase() === 'mtb race') {
			rate = 95;
		}
		return rate;
	},
};
