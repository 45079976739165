import React, { useState, useContext } from 'react';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';
import parser from 'parse-address';
import { StripeCardInput, Button } from '../components/ReactRainbow';
import { AppContext } from '../context';
import BuddyAPI from '../models/BuddyAPI';
import BuddyInsuranceOffer from '../components/BuddyInsuranceOffer';

const getPropertyFromGoogleAddress = (ga, prop, returnProp = 'long_name') => ga.find((el) => el.types[0] === prop)[returnProp];

const PAY_KEY = process.env.REACT_APP_PAY_KEY;

const Checkout = ({
	productType = null, priceModel, venue, titleOverRide,
}) => {
	const {
		appState: {
			product, pricing, dateRange, customer, isPurchasing, selectedOffering, offerPlacement,
		},
		setAppState,
		displayError,
	} = useContext(AppContext);

	const [stripeCard, setStripeCard] = useState();

	const [isLoading, setIsLoading] = useState(false);

	// specific to wildlife
	const productName =		product?.name === 'both' ? 'hunting & fishing' : product?.name;
	const days =		dateRange.length === 1
		? 1
		: moment(dateRange[1]).diff(moment(dateRange[0]), 'days');
	const cost = priceModel.calculateCost({ days, type: product?.name });
	const totalPricing = ((isPurchasing && pricing) || 0) + cost;
	const isTicketCancellation = selectedOffering.includes('TICKET_CANCELLATION');

	const handleSubmit = async () => {
		if (!isPurchasing) {
			navigate('thanks');
			return;
		}
		setIsLoading(true);
		const { address: gaAddressObject } = customer;

		const { address_components: ga } = gaAddressObject;

		const addressFormatter = (address) => {
			const {
				number, street, type, city, state, zip,
			} = address;
			const formattedAddress = {
				line1: number + street + type,
				city,
				state,
				zip,
			};
			return formattedAddress;
		};

		// converts address to an object the API will accept, if the user typed in their address earlier in flow
		const address = (typeof customer.address === 'object') ? {
			line1: `${getPropertyFromGoogleAddress(
				ga,
				'street_number',
			)} ${getPropertyFromGoogleAddress(ga, 'route')}`,
			city: getPropertyFromGoogleAddress(ga, 'locality'),
			state: getPropertyFromGoogleAddress(
				ga,
				'administrative_area_level_1',
				'short_name',
			),
			zip: getPropertyFromGoogleAddress(ga, 'postal_code', 'short_name'),
		} : addressFormatter(parser.parseInformalAddress(customer.address));

		const {
			name, email, phone, dob, addressExtra,
		} = customer;
		if (addressExtra) {
			address.line2 = addressExtra;
		}
		const customerToSend = {
			name,
			dob,
			phone,
			email,
			address,
		};
		const isToday = moment().isSame(moment(dateRange[0]), 'day');
		let endDate;
		if (dateRange.length === 1) {
			endDate = moment(dateRange[0])
				.add(isToday ? 1 : 0, 'days')
				.format('MM/DD/YYYY');
		} else {
			endDate = moment(dateRange[1]).format('MM/DD/YYYY');
		}
		const policyToSend = {
			offering: selectedOffering,
			startDate: moment(dateRange[0]).format('MM/DD/YYYY'),
			endDate,
			premiumTotal: pricing,
		};

		if (selectedOffering === 'BUDDY_ACCIDENT_V2') {
			policyToSend.who = 'ME';
			policyToSend.activities = product?.name === 'both' ? ['hunting', 'fishing'] : [product?.name];
			policyToSend.covered = [{ type: 'ME', name, dob }];
		}

		if (isTicketCancellation) {
			policyToSend.numberOfTickets = 1; // TODO: remove hard code here
			policyToSend.totalTicketPrice = cost;
		}

		try {
			const order = await BuddyAPI.order({
				customer: customerToSend,
				policy: policyToSend,
				venue,
			});
			setAppState({ orderID: order.orderID });
			navigate('thanks');
		} catch (error) {
			displayError(error.message || error, 'Error');
		}
		setIsLoading(false);
	};
	if (offerPlacement === 'with-checkout') {
		return (
			<div className="mx-auto mb-5 w-11/12">
				<div className="text-4xl text-center mb-6 mt-4 px-4">Checkout</div>
				<BuddyInsuranceOffer priceModel={priceModel} venue={venue} titleOverRide={titleOverRide} />
				<hr className="w-1/2 mx-auto bg-gray-900" />
				<div className="flex flex-row w-1/2 mx-auto justify-between mb-5">
					<div className="flex flex-col font-bold text-xl capitalize">
						{productType ? `${productName} ${productType}` : productName}
						:
					</div>
					<div className="flex flex-col my-auto">{Math.toUSD(cost)}</div>
				</div>

				{isPurchasing && (
					<div className="flex flex-row w-1/2 mx-auto justify-between mb-4">
						<div className="flex flex-col font-bold text-xl">
							Buddy Insurance:
						</div>
						<div className="flex flex-col my-auto">{Math.toUSD(pricing)}</div>
					</div>
				)}

				<div className="flex flex-row w-1/2 mx-auto justify-between mb-8">
					<div className="flex flex-col font-bold text-3xl">Total:</div>
					<div className="flex flex-col my-auto text-lg">
						{Math.toUSD(totalPricing)}
					</div>
				</div>
				<div className="w-1/2 mx-auto">
					<StripeCardInput
						apiKey={PAY_KEY}
						label="Credit/Debit Card Information"
						labelAlignment="left"
						onChange={setStripeCard}
						error={stripeCard && stripeCard.error && stripeCard.error.message}
					/>
					<Button
						isLoading={isLoading}
						className="mt-4 px-24 float-right"
						label="Submit"
						variant="success"
						onClick={handleSubmit}
						disabled={!stripeCard || !stripeCard.isComplete}
					/>
				</div>
			</div>
		);
	}
	return (
		<div className="mx-auto mb-5 w-5/6">
			<div className="text-4xl text-center mb-4 px-4">Checkout</div>
			<div className="flex flex-row w-1/2 mx-auto justify-between mb-5">
				<div className="flex flex-col font-bold text-xl capitalize">
					{productType ? `${productName} ${productType}` : productName}
					:
				</div>
				<div className="flex flex-col my-auto">{Math.toUSD(cost)}</div>
			</div>
			{isPurchasing && (
				<div className="flex flex-row w-1/2 mx-auto justify-between mb-4">
					<div className="flex flex-col font-bold text-xl">
						Buddy Insurance:
					</div>
					<div className="flex flex-col my-auto">{Math.toUSD(pricing)}</div>
				</div>
			)}
			<hr className="w-1/2 mx-auto bg-gray-900" />
			<div className="flex flex-row w-1/2 mx-auto justify-between mb-8">
				<div className="flex flex-col font-bold text-3xl">Total:</div>
				<div className="flex flex-col my-auto text-lg">
					{Math.toUSD(totalPricing)}
				</div>
			</div>
			<div className="w-1/2 mx-auto">
				<StripeCardInput
					apiKey={PAY_KEY}
					label="Credit/Debit Card Information"
					labelAlignment="left"
					onChange={setStripeCard}
					error={stripeCard && stripeCard.error && stripeCard.error.message}
				/>
				<Button
					isLoading={isLoading}
					className="mt-4 px-24 float-right"
					label="Submit"
					variant="success"
					onClick={handleSubmit}
					disabled={!stripeCard || !stripeCard.isComplete}
				/>
			</div>
		</div>
	);
};

export default Checkout;
