import React, { useContext } from 'react';
import { Input, Select } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';
import ACTIVITIES from '../../models/CommercialActivities';

const ActivityBreakdown = ({
	activity, onChange, index, removeActivity,
}) => {
	const choice = ACTIVITIES.find(({ value }) => value === activity.name);
	const { showGuides, showUnits } = choice;
	return (
		<div className="border rounded py-4 px-8">
			<IF condition={index > 0}>
				<button
					type="button"
					className="float-right"
					onClick={() => removeActivity(index)}
				>
					<div className="flex items-center text-red-500">
						<span>Remove</span>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
						</svg>
					</div>
				</button>
			</IF>
			<Select
				options={ACTIVITIES}
				value={activity.name}
				onChange={(event) => onChange(event, index)}
				className="md:w-1/2 mt-4"
				label="Activity"
				labelAlignment="left"
				name="name"
			/>
			<IF condition={showGuides}>
				<Input
					className="w-full md:w-1/4 mt-4"
					label="Number of Guides"
					labelAlignment="left"
					placeholder=""
					type="number"
					value={activity.guides || ''}
					name="guides"
					onChange={(event) => onChange(event, index)}
				/>
			</IF>
			<IF condition={showUnits}>
				<Input
					className="w-full md:w-1/4 mt-4"
					label="Number of Units"
					labelAlignment="left"
					placeholder=""
					type="number"
					value={activity.units || ''}
					name="units"
					onChange={(event) => onChange(event, index)}
				/>
			</IF>
			<Input
				className="w-full md:w-1/4 mt-4"
				label="User Days"
				labelAlignment="left"
				placeholder=""
				type="number"
				value={activity.userDays || ''}
				name="userDays"
				onChange={(event) => onChange(event, index)}
			/>
			<Input
				className="w-full md:w-1/4 mt-4"
				label="Revenues"
				labelAlignment="left"
				placeholder=""
				type="text"
				value={activity.revenues || ''}
				name="revenues"
				onChange={(event) => onChange(event, index)}
			/>
		</div>
	);
};

const defaultActivity = {
	name: 'GuidedFishing', guides: '', userDays: '', revenues: '',
};

function ActivityInfo() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		lastYearRevenue = '',
		nextYearRevenue = '',
		activities = [defaultActivity],
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	const handleChangeActivity = ({ target: { name, value } }, index) => {
		const updatedField = { ...activities[index], [name]: value };
		const activitiesArray = [...activities];
		activitiesArray.splice(index, 1, updatedField);
		setAppState({ activities: activitiesArray });
	};

	const addActivity = () => {
		const updatedActivities = [...activities];
		updatedActivities.push(defaultActivity);
		setAppState({ activities: updatedActivities });
	};

	const removeActivity = (index) => {
		const updatedActivities = [...activities];
		updatedActivities.splice(index, 1);
		setAppState({ activities: updatedActivities });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART II - ACTIVITY INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				<Input
					className="w-full md:w-1/2"
					label="Actual Total Receipts for Prior 12 Months"
					labelAlignment="left"
					placeholder=""
					type="text"
					value={lastYearRevenue}
					name="lastYearRevenue"
					required
					onChange={handleChange}
				/>
				<Input
					className="w-full md:w-1/2 mt-4"
					label="Estimated Total Receipts for Next 12 Months"
					labelAlignment="left"
					placeholder=""
					type="text"
					value={nextYearRevenue}
					name="nextYearRevenue"
					required
					onChange={handleChange}
				/>
				<div className="my-4 border-t border-gray-300" />
				<p className="my-4 font-bold">
					Activities Conducted
				</p>
				{ activities.map((act, i) => (
					<ActivityBreakdown
						activity={act}
						index={i}
						// eslint-disable-next-line react/no-array-index-key
						key={`activity-${i}`}
						onChange={handleChangeActivity}
						removeActivity={removeActivity}
					/>
				))}
				<div className="flex mt-4 justify-end">
					<button
						type="button"
						onClick={addActivity}
						className="focus:outline-none focus:text-underline hover:text-underline"
					>
						<div className="flex text-blue-400 items-center">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
							</svg>
							<span>Add activity</span>
						</div>
					</button>
				</div>
			</form>
		</div>
	);
}

export default ActivityInfo;
