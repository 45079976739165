import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Link, navigate } from '@reach/router';
import { AppContext } from '../context';
// import ErrorBoundary from '../components/ErrorBoundary';
import {
	Input,
	Button,
	CheckboxToggle,
	Spinner,
	Card,
	StrongPasswordInput,
} from '../components/ReactRainbow';
import IF from '../components/IF';

const validateEmail = (value) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value);

const isPasswordValid = (password) => (
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
		.test(password));

const STATUS = {
	LOGIN: 'login',
	CONFIRM_LOGIN: 'confirm-login',
	CHANGE_PASSWORD: 'change-password',
};

const Login = () => {
	const { appState, setAppState, displayError } = useContext(AppContext);
	const [status, setStatus] = useState(STATUS.LOGIN);
	const [creds, setCreds] = useState({
		username: '',
		password: '',
		code: '',
		newPassword: '',
	});
	const { authData, intendedRoute } = appState;
	const [rememberDevice, setRememberDevice] = useState(true);
	const [loading, setLoading] = useState(false);
	const [refreshing, setRefreshing] = useState(true);
	const [saveLogin, setSaveLogin] = useState(true);
	const {
		username, password, code, newPassword,
	} = creds;

	useEffect(() => {
		const onRefresh = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();
				setAppState({ authData: user, partnerData: null });
				navigate('/');
			} catch (error) {
				setRefreshing(false);
				if (process.env.NODE_ENV !== 'test') {
					// eslint-disable-next-line no-console
					console.log(`on refresh: ${error}`);
				}
			}
		};
		onRefresh();
	}, [setAppState, intendedRoute]);

	// Doing this on the front end since AWS amplify doesn't have a way of having
	const forgetDevice = () => {
		const cognitoKeys = Object.keys(window.localStorage).filter((key) => key.startsWith('CognitoIdentityServiceProvider'));
		cognitoKeys.forEach((key) => {
			window.localStorage.removeItem(key);
		});
	};

	const signIn = async (event) => {
		event.preventDefault();
		setLoading(true);
		// trackEvent('sign_in', 'click');
		if (!validateEmail(username)) {
			setLoading(false);
			displayError(
				'Invalid email address. Please check the email address and try again.',
				true,
			);
			return;
		}
		try {
			const data = await Auth.signIn(username.toLowerCase(), password);
			if (!saveLogin) {
				forgetDevice();
			}
			// check the partner situation
			setLoading(false);
			setAppState({ authData: data });
			if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
				setStatus(STATUS.CHANGE_PASSWORD);
			} else if (data.challengeName === 'SMS_MFA') {
				setStatus(STATUS.CONFIRM_LOGIN);
			} else if (intendedRoute) {
				navigate(intendedRoute);
			} else {
				navigate('/');
			}
		} catch (err) {
			if (err.code === 'PasswordResetRequiredException') {
				navigate('/forgot-password');
				displayError(
					'A password reset is required for your account. Please reset your password using the Forgot Password feature.',
				);
				return;
			}
			displayError(err.message || err);
			setLoading(false);
		}
	};

	const confirmDevice = async (user) => {
		try {
			await user.getCachedDeviceKeyAndPassword();
			await new Promise((resolve, reject) => {
				user.setDeviceStatusRemembered({
					// or setDeviceStatusNotRemembered
					onSuccess: resolve,
					onFailure: reject,
				});
			});
			return 'SUCCESS';
		} catch (error) {
			return Promise.reject(error);
		}
	};

	const confirmSignIn = async (event) => {
		event.preventDefault();
		setLoading(true);
		try {
			await Auth.confirmSignIn(authData, code, null);
			const user = await Auth.currentAuthenticatedUser();
			if (rememberDevice) {
				await confirmDevice(user);
			}
			setAppState({ authData: user });
			navigate('/');
		} catch (error) {
			if (error.code === 'PasswordResetRequiredException') {
				navigate('/forgot-password');
				displayError(
					'A password reset is required for your account. Please reset your password using the Forgot Password feature.',
				);
				return;
			}
			displayError(error.message || error);
			setLoading(false);
		}
	};

	const changePassword = async (event) => {
		event.preventDefault();
		// trackEvent('change_password', 'click');
		setLoading(true);
		try {
			const res = await Auth.completeNewPassword(authData, newPassword, {});
			if (res.challengeName === 'SMS_MFA') {
				setStatus(STATUS.CONFIRM_LOGIN);
			} else {
				// we need the full CognitoIdentity Object for the rest of the app, so getting it below.
				const user = await Auth.currentAuthenticatedUser();
				setAppState({ authData: user });
				navigate('/');
			}
		} catch (error) {
			displayError(error);
		}
		setLoading(false);
	};

	const handleChange = ({ target: { name, value } }) => {
		setCreds((current) => ({ ...current, [name]: value }));
	};

	const getPasswordState = () => {
		if (!newPassword.length) {
			return null;
		}
		if (isPasswordValid(newPassword)) {
			return 'strong';
		}
		return 'weak';
	};

	if (refreshing) {
		return (
			<div className="w-full h-screen flex justify-center items-center">
				<Spinner variant="brand" />
			</div>
		);
	}

	return (
		<>
			<div className="container mx-auto flex justify-center h-screen items-center">
				<div className="w-full sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2">
					<Card
						icon={(
							<img
								className="w-8"
								src="/assets/buddy_lettermark_R.svg"
								alt="logo"
							/>
						)}
						title="Sign In | Integration Demo"
					>
						<form className="p-3 sm:p-8 w-full md:w-11/12 lg:w-5/6 xl:w-4/5 mx-auto">
							<IF condition={status === STATUS.LOGIN}>
								<>
									<Input
										label="Email"
										placeholder="username@example.com"
										type="text"
										autoComplete="username"
										labelAlignment="left"
										name="username"
										onChange={handleChange}
										value={username}
									/>
									<Input
										className="mt-5"
										label="Password"
										placeholder="********"
										type="password"
										autoComplete="current-password"
										labelAlignment="left"
										name="password"
										onChange={handleChange}
										value={password}
									/>
									<CheckboxToggle
										className="mt-5 ml-auto"
										id="remember-device"
										label="Keep me signed in"
										value={saveLogin}
										onChange={() => setSaveLogin(!saveLogin)}
									/>
									<Button
										type="submit"
										variant="brand"
										label="Sign In"
										style={{ display: 'block' }}
										className="mt-5 ml-auto"
										disabled={!username.length || !password.length || loading}
										isLoading={loading}
										onClick={signIn}
									/>
									<Link
										to="/forgot-password"
										className="link mt-8 text-center block"
									>
										Forgot Password
									</Link>
								</>
							</IF>
							<IF condition={status === STATUS.CONFIRM_LOGIN}>
								<>
									<h2 className="text-center text-2xl font-bold mb-3">Verify It's You</h2>
									<p className="text-center">
										A code was just sent to
										{' '}
										{authData?.challengeParam?.CODE_DELIVERY_DESTINATION
											|| 'your phone'}
										.
									</p>
									<Input
										className="mt-5"
										label="Code"
										placeholder="******"
										labelAlignment="left"
										name="code"
										onChange={handleChange}
										value={code}
										autoComplete="one-time-code"
									/>
									<Button
										type="button"
										variant="base"
										label="Re-send Code"
										onClick={signIn}
										isLoading={loading}
										style={{ display: 'block' }}
										className="mx-auto"
									/>
									<CheckboxToggle
										className="mt-5 ml-auto"
										id="remember-device"
										label="Remember this device"
										value={rememberDevice}
										onChange={() => setRememberDevice(!rememberDevice)}
									/>
									<div className="flex justify-between mt-5">
										<Button
											type="button"
											variant="base"
											label="Back"
											onClick={() => setStatus(STATUS.LOGIN)}
										/>
										<Button
											type="submit"
											variant="brand"
											label="Next"
											disabled={!code.length || code.length !== 6}
											isLoading={loading}
											onClick={confirmSignIn}
										/>
									</div>
								</>
							</IF>
							<IF condition={status === STATUS.CHANGE_PASSWORD}>
								<>
									<div className="text-center mt-4 sm:mt-8">
										Please choose a new password for your account.
									</div>
									<StrongPasswordInput
										className="mt-5"
										placeholder="********"
										minLength={8}
										type="password"
										autoComplete="new-password"
										hideLabel
										name="newPassword"
										value={newPassword}
										required
										passwordState={getPasswordState()}
										bottomHelpText="Your password must be at least 8 characters long, include at least one number, one uppercase letter and one lowercase letter and a special character."
										onChange={handleChange}
									/>
									<Button
										type="submit"
										variant="brand"
										label="Update Password"
										style={{ display: 'block' }}
										className="mt-5 ml-auto"
										disabled={!newPassword.length}
										isLoading={loading}
										onClick={changePassword}
									/>
								</>
							</IF>
						</form>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Login;
