import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import useWindowSize from '../hooks/useWindowSize';
import { AppContext } from '../context';
import { Calendar, Card, RadioGroup } from '../components/ReactRainbow';

const standardDateOptions = [
	{ value: 'oneday', label: '1 Day' },
	{ value: 'annual', label: 'Year Round' },
	{ value: 'range', label: 'Specific Date Range' },
];
const calLabels = {
	oneday: 'Which day?',
	annual: 'Which day do you want to start?',
	range: 'What specific dates?',
};

const Duration = ({ titleOverride, dateOptions }) => {
	const dateOptionsToUse = dateOptions || standardDateOptions;
	const {
		appState: {
			product, duration = dateOptionsToUse[0], dateRange = null, selectedOffering,
		},
		setAppState,
	} = useContext(AppContext);
	const { width } = useWindowSize();
	const starterRange = dateRange || [new Date()];
	const [selectedTimeframe, setSelectedTimeframe] = useState(duration);
	// TODO: pull ticket dates in from offering.
	const ticketDates = [
		moment().add(64, 'days'),
		moment().add(67, 'days'),
	];
	const ticketDateValues = ticketDates.map((el) => el.toDate());
	const displayDates = ticketDates.map((el) => el.format('MM/DD/YYYY'));
	const isTicketCancellation = selectedOffering.includes('TICKET_CANCELLATION');

	const [selectedRange, setSelectedRange] = useState(starterRange);

	// update the context when needed
	useDidUpdateEffect(() => {
		setAppState({
			duration: selectedTimeframe,
			dateRange: selectedRange,
			shouldRefreshPricing: true,
		});
	}, [selectedRange]);

	const handleTimeframeChange = (val) => {
		if (val === selectedTimeframe) {
			return;
		}
		// check if it's a range, if so, add a day
		setSelectedTimeframe(val);

		if (val === 'range') {
			setSelectedRange([
				selectedRange[0],
				moment(selectedRange[0]).add(2, 'days').toDate(),
			]);
		} else if (val === 'annual') {
			setSelectedRange([
				selectedRange[0],
				moment(selectedRange[0]).add(364, 'days').toDate(),
			]);
		} else {
			setSelectedRange([selectedRange[0]]);
		}
	};

	const title = `When would you like to go${
		product?.verb ? ` ${product.verb}` : ''
	}?`;

	const calendarContainerStyles =		width < 576
		? { width: '100%', height: 'auto ' }
		: { width: '22rem', height: '22rem' };

	if (isTicketCancellation) {
		return (
			<div className="mb-5">
				<div className="text-4xl text-center mb-4">Event Dates (read-only)</div>
				<p className="mt-5 text-center">
					{`${displayDates[0]} - ${displayDates[1]}`}
				</p>
				<div className="flex flex-col md:flex-row justify-around">
					<div className="flex flex-col">
						<Card style={calendarContainerStyles} className="md:p-5">
							<Calendar
								className="mb-8"
								hideLabel
								id="datePicker-1"
								value={ticketDateValues}
								selectionType="range"
								label="Date"
								formatStyle="large"
							/>
						</Card>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4">{titleOverride || title}</div>
			<div className="flex flex-col md:flex-row justify-around">
				{dateOptionsToUse.length ? (
					<div className="flex flex-col">
						<label className="mt-5 pl-5" htmlFor="timeframe-selection">
							Select your timeframe
						</label>
						<RadioGroup
							id="timeframe-selection"
							options={dateOptionsToUse}
							value={selectedTimeframe}
							onChange={({ target }) => handleTimeframeChange(target.value)}
							label="Radio Group Label"
							hideLabel
						/>
					</div>
				) : null}
				<div className="flex flex-col">
					<label className="mt-5 pl-5" htmlFor="datePicker-1">
						{calLabels[selectedTimeframe]}
					</label>
					<Card style={calendarContainerStyles} className="md:p-5">
						<Calendar
							className="mb-8"
							hideLabel
							id="datePicker-1"
							value={
								['annual', 'oneday'].includes(selectedTimeframe)
									? selectedRange
									: selectedRange
							}
							minDate={new Date()}
							maxDate={moment().add(1, 'year').subtract(1, 'day').toDate()}
							selectionType={
								['annual', 'oneday'].includes(selectedTimeframe)
									? 'single'
									: 'range'
							}
							onChange={(val) => setSelectedRange(Array.isArray(val) ? val : [val])}
							label="Date"
							formatStyle="large"
						/>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default Duration;
