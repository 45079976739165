import React from 'react';
import { VisualPicker, VisualPickerOption, VisualPickerOptionFooter } from '../../components/ReactRainbow';

const options = [
	{ path: 'calendar', img: '/assets/calendar.svg', name: 'Calendar' },
	{ path: 'customers', img: '/assets/customers.svg', name: 'Customers' },
	{ path: 'reports', img: '/assets/data.svg', name: 'Reports' },
	{ path: 'settings', img: '/assets/settings.svg', name: 'Settings' },
];

function Dashboard({ title, navigateNext }) {
	const handleClick = (value) => {
		if (value === 'settings') {
			navigateNext();
		}
	};

	return (
		<div>
			<h2 className="text-2xl font-bold text-center mt-4">{title || 'Home'}</h2>
			<div className="flex justify-center">
				<VisualPicker
					id="visual-picker-component-5"
					hideLabel
					label="Select a demo type"
					onChange={(value) => handleClick(value)}
					className="mt-8"
				>
					{options.map(({ path, img, name }) => (
						<VisualPickerOption
							name={path.replace('*', '')}
							key={path}
							className="mx-10"
							style={{ maxWidth: 142 }}
							footer={<VisualPickerOptionFooter label={name} />}
						>
							<img style={{ width: '70px' }} src={img} alt={name} />
						</VisualPickerOption>
					))}
				</VisualPicker>
			</div>
		</div>
	);
}

export default Dashboard;
