import fetch from 'isomorphic-fetch';

const partnerID = 'p-buddytest';
const PARTNER_API_URL = process.env.REACT_APP_PARTNER_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

const serialize = (obj, startChar = '?') => {
	const str = [];
	Object.keys(obj).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
		}
	});
	return `${startChar}${str.join('&')}`;
};

const DEFAULT_OFFERINGS = [{ id: 'BUDDY_ACCIDENT_V2' }, { id: 'ITC_TICKET_CANCELLATION' }, { id: 'COMMERCIAL' }];

// helper function to generate the FETCHES
const createFetch = async (options) => {
	const {
		url, method = 'GET', body = null, token,
	} = options;

	const obj = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	};

	if (body) {
		obj.body = JSON.stringify(body);
	}
	if (token) {
		obj.headers.Authorization = `Bearer ${token}`;
	}

	return fetch(url, obj);
};

export default {
	getOfferings: async (token) => {
		try {
			if (!token) {
				return Promise.reject(new Error('Token required to get offerings.'));
			}
			const url = `${API_URL}/v2/offering`;
			const resp = await createFetch({ url, token });
			const json = await resp.json();
			if (!json.ok) {
				return Promise.reject(json.error);
			}
			return json.offerings?.length ? DEFAULT_OFFERINGS.concat(json.offerings) : DEFAULT_OFFERINGS;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	getQuote: async (options) => {
		Object.assign(options, {
			partnerID,
		});
		// default to buddy accident
		if (!options.offering) {
			// eslint-disable-next-line no-param-reassign
			options.offering = 'BUDDY_ACCIDENT_V2';
		}
		try {
			const url = `${PARTNER_API_URL}/v1/quote${serialize(options)}`;
			const resp = await createFetch({ url });
			const json = await resp.json();
			if (!json.ok) {
				return Promise.reject(json.message);
			}
			return json.pricing;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	order: async (body) => {
		const url = `${PARTNER_API_URL}/v1/order`;
		const method = 'POST';

		const fakePaymentInfo = {
			paymentType: 'CARD',
			number: '4242424242424242',
			expiration: '12/24',
			cvc: '123',
		};

		Object.assign(body, { partnerID, paymentInfo: fakePaymentInfo });
		try {
			const resp = await createFetch({ url, method, body });
			const json = await resp.json();
			if (!json.ok) {
				return Promise.reject(json.message);
			}
			return json.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	orderCommercial: async (payload) => {
		const url = 'https://staging.partners.buddyinsurance.com/v1/demo/order?partnerID=p-buddytest';
		const method = 'POST';
		const emailID = 'COMMERCIAL_APPLICATION';
		const pdf = 'guides.pdf';

		const body = { emailID, pdf, payload };
		try {
			const resp = await createFetch({ url, method, body });
			const json = await resp.json();
			if (!json.ok) {
				return Promise.reject(json.message);
			}
			return json.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
};
