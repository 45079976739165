import React, { useContext } from 'react';
import { Link, navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPowerOff,
	faDesktop,
} from '@fortawesome/free-solid-svg-icons';
import { AvatarMenu, MenuDivider, MenuItem } from './ReactRainbow';
import { AppContext } from '../context';

const Header = ({ header, logo, theme }) => {
	const { brand } = theme?.rainbow?.palette;
	const {
		appState: { authData },
		setAppState,
		resetApp,
	} = useContext(AppContext);

	const signOut = () => {
		try {
			authData.signOut();
		} catch (error) {
			console.log(error);
		}
		setAppState({ authData: null });
		navigate('/login');
	};

	return (
		<div
			style={{ height: '100px', backgroundColor: brand }}
			className="flex flex-row justify-between items-center w-full p-3"
		>
			<div className="flex w-full">
				<Link className="my-auto ml-4" to="/">
					<img
						src={logo || '/assets/buddy_lettermark_W.svg'}
						className=""
						style={{ height: '80px' }}
						alt="logo"
						onClick={resetApp}
						data-testid="home-logo"
					/>
				</Link>
				<h1 className="text-white text-4xl font-bold ml-4 my-auto">
					{header}
				</h1>
			</div>
			<AvatarMenu
				className="ml-4"
				id="account-menu"
				src="/assets/cog.png"
				assistiveText="Account Settings"
				menuAlignment="right"
				menuSize="small"
				avatarSize="medium"
				header="Profile Settings"
				avatarBackgroundColor={brand}
			>
				<div className="pl-3">
					<div className="">{authData?.username || ''}</div>
				</div>
				<MenuDivider variant="space" />
				<MenuItem
					label="Choose another demo"
					icon={<FontAwesomeIcon icon={faDesktop} />}
					iconPosition="left"
					onClick={() => navigate('/')}
				/>
				<MenuDivider variant="space" />
				<MenuItem
					label="Logout"
					icon={<FontAwesomeIcon icon={faPowerOff} />}
					iconPosition="left"
					onClick={signOut}
				/>
			</AvatarMenu>
		</div>
	);
};

export default Header;
