import React, { useState, useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { Link } from '@reach/router';
import { Input, GoogleAddressLookup } from '../components/ReactRainbow';
import { AppContext } from '../context';

const LIBRARY_GOOGLE_MAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const WhoAreYou = () => {
	const {
		appState: { customer = {} },
		setAppState,
	} = useContext(AppContext);

	const [customerInfo, setCustomerInfo] = useState({ ...customer });

	const {
		name = '',
		email = '',
		dob = '',
		phone = '',
		address = '',
		addressExtra = '',
	} = customerInfo;

	const updateCustomer = (update) => {
		// const customerUpdate = Object.assign(customerInfo, update);
		setCustomerInfo((current) => ({ ...current, ...update }));
		// setAppState({ customer: customerUpdate });
	};

	const fillData = {
		name: 'Shepard Fairey',
		email: 'mabl1@iambuddy.com',
		dob: '02/15/1970',
		phone: '281-330-8004',
		address: '1717 East Cary Street Richmond VA 23223',
	};

	const fillForms = (prefillData) => {
		const filledCustomer = Object.assign(customerInfo, prefillData);
		setCustomerInfo(filledCustomer);
		setAppState({ customer: filledCustomer });
	};

	const updateAddress = (update) => {
		const customerUpdate = Object.assign(customerInfo, update);
		setCustomerInfo(customerUpdate);
		setAppState({ customer: customerUpdate });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4" onClick={() => fillForms(fillData)}>Who are you?</div>
			<button
				type="button"
				onClick={() => fillForms(fillData)}
				className="text-white"
			>
				Fill
			</button>
			<form autoComplete="on">
				<div className="flex flex-col md:flex-row md:mb-5">
					<Input
						className="w-full md:w-1/2 px-4"
						label="Full Name"
						labelAlignment="left"
						placeholder="Elvis Presley"
						type="text"
						value={name}
						name="name"
						required
						onChange={(val) => updateCustomer({ name: val.target.value })}
						onBlur={() => setAppState({ customer: customerInfo })}
						autoComplete="name"
					/>
					<div className="w-full md:w-1/2 px-4">
						<label className="rainbow-label-lookalike" htmlFor="dob">
							<abbr title="required" className="rainbow-asterisk">
								*
								{' '}
							</abbr>
							Date of Birth
						</label>
						<MaskedInput
							mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
							// react-text-mask doesn't support type=number, so type=tel is subbed for mobile use.
							type="tel"
							placeholder="Date of birth (MM/DD/YYYY)"
							id="dob"
							className="rainbow-input-lookalike"
							name="dob"
							guide={false}
							onChange={(val) => updateCustomer({ dob: val.target.value })}
							onBlur={() => setAppState({ customer: customerInfo })}
							required
							value={dob}
							autoComplete="bday"
						/>
					</div>
				</div>
				<div className="flex flex-col md:flex-row md:mb-5">

					<GoogleAddressLookup
						className="w-full md:w-1/2 px-4 mt-8 md:mt-0"
						id="gaddresslookup-1"
						label="Resident Address"
						labelAlignment="left"
						onChange={(val) => updateAddress({ address: val })}
						onBlur={() => updateAddress({ address: document.getElementsByName('street-address')[0].value })}
						value={address}
						placeholder="Enter location"
						required
						apiKey={LIBRARY_GOOGLE_MAPS_APIKEY}
						autoComplete="street-address"
						name="street-address"
					/>
					<Input
						className="w-full md:w-1/2 px-4 mt-6 md:mt-0"
						label="Suite/Apt. #"
						labelAlignment="left"
						placeholder="#323"
						type="text"
						value={addressExtra}
						onChange={(val) => updateCustomer({ addressExtra: val.target.value })}
						onBlur={() => setAppState({ customer: customerInfo })}
					/>
				</div>
				<div className="flex flex-col md:flex-row md:mb-5">
					<Input
						className="w-full md:w-1/2 px-4 mt-8 md:mt-0"
						label="Email"
						name="email"
						labelAlignment="left"
						placeholder="elvis@presley.com"
						type="email"
						value={email}
						required
						onChange={(val) => updateCustomer({ email: val.target.value })}
						onBlur={() => setAppState({ customer: customerInfo })}
						autoComplete="email"
					/>
					<div className="w-full md:w-1/2 px-4">
						<label className="rainbow-label-lookalike" htmlFor="dob">
							<abbr title="required" className="rainbow-asterisk">
								*
								{' '}
							</abbr>
							Mobile Phone
						</label>
						<MaskedInput
							mask={[
								'(',
								/[2-9]/,
								/\d/,
								/\d/,
								')',
								' ',
								/\d/,
								/\d/,
								/\d/,
								'-',
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							]}
							id="phone"
							name="phone"
							type="tel"
							guide={false}
							value={phone}
							className="rainbow-input-lookalike"
							placeholder="(555) 867-5309"
							onChange={(val) => updateCustomer({ phone: val.target.value })}
							onBlur={() => setAppState({ customer: customerInfo })}
							aria-describedby="phoneHelp"
							required
							autoComplete="tel"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default WhoAreYou;
