Math.roundTo = (n, digits = 0) => {
	const multiplicator = 10 ** digits;
	const newN = parseFloat((n * multiplicator).toFixed(11));
	const test = Math.round(newN) / multiplicator;
	return +test.toFixed(digits);
};
Math.toUSD = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2,
}).format;
Math.toDisplay = new Intl.NumberFormat('en-US').format;
Math.toPercentage = (num) =>
	`${new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num * 100)}%`;
Math.dollarsToCents = (dollars) => Math.round(dollars * 100);
Math.centsToDollars = (cents) => Numbers.roundTo(cents / 100, 2);
