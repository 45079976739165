import React, { useContext } from 'react';
import { Input, CheckboxGroup } from '../../components/ReactRainbow';
import { AppContext } from '../../context';
import IF from '../../components/IF';
import YesNo from '../../components/YesNo';

const opOptions = [
	{ value: 'typeOfOperationBoatRentalCheck', label: 'Boat Rentals' },
	{ value: 'typeOfOperationFishingTripsCheck', label: 'Fishing Trips' },
	{ value: 'typeOfOperationTubeOrCanoeRentalCheck', label: 'Tube or Canoe Rentals' },
	{ value: 'typeOfOperationHuntingCheck', label: 'Hunting' },
	{ value: 'typeOfOperationOtherCheck', label: 'Other' },
];

const waterOptions = [
	{ value: 'bodiesOfWaterUseTakesPlaceRiversCheck', label: 'Rivers' },
	{ value: 'bodiesOfWaterUseTakesPlaceLakesCheck', label: 'Lakes' },
	{ value: 'bodiesOfWaterUseTakesPlaceOcean', label: 'Ocean' },
	{ value: 'bodiesOfWaterUseTakesPlaceBaysInlets', label: 'Bays/Inlets' },
];

const riverOptions = [
	{ value: 'riverClass1Check', label: 'Class I' },
	{ value: 'riverClass2Check', label: 'Class II' },
	{ value: 'riverClass3Check', label: 'Class III' },
	{ value: 'riverClass4Check', label: 'Class IV' },
	{ value: 'riverClass5Check', label: 'Class V' },
];

function BoatGeneralInfo() {
	const {
		appState,
		setAppState,
	} = useContext(AppContext);

	const {
		waterCraftOperationTypes = [],
		otherWaterCraftOperation = '',
		bodiesOfWater = [],
		riverClasses = [],
		lifeVestsRequired,
		lifeVestsProvided,
	} = appState;

	const handleChange = ({ target: { value, name: keyName } }) => {
		setAppState({ [keyName]: value });
	};

	return (
		<div className="mb-5">
			<div className="text-4xl text-center mb-4 md:px-4">General Liability Application</div>
			<p className="my-4 md:w-3/4 mx-auto font-bold">
				PART VII - WATERCRAFT GENERAL INFORMATION
			</p>
			<form className="md:w-3/4 mx-auto">
				<CheckboxGroup
					label="What types of operation do you have?"
					labelAlignment="left"
					onChange={(values) => setAppState({ waterCraftOperationTypes: values })}
					options={opOptions}
					value={waterCraftOperationTypes}
					className="mt-4"
				/>
				<IF condition={waterCraftOperationTypes.includes('typeOfOperationOtherCheck')}>
					<Input
						className="w-full md:w-1/2"
						onChange={handleChange}
						label="Specify Other:"
						labelAlignment="left"
						value={otherWaterCraftOperation}
						name="otherWaterCraftOperation"
					/>
				</IF>
				<CheckboxGroup
					label="On what bodies of water does use take place?"
					labelAlignment="left"
					onChange={(values) => setAppState({ bodiesOfWater: values })}
					options={waterOptions}
					value={bodiesOfWater}
					className="mt-4"
				/>
				<IF condition={bodiesOfWater.includes('bodiesOfWaterUseTakesPlaceRiversCheck')}>
					<CheckboxGroup
						label="What river classes are boated?"
						labelAlignment="left"
						onChange={(values) => setAppState({ riverClasses: values })}
						options={riverOptions}
						value={riverClasses}
						className="mt-4"
					/>
				</IF>
				<YesNo
					label="Are life vests (PFD’s) required?"
					value={lifeVestsRequired}
					onChange={handleChange}
					name="lifeVestsRequired"
				/>
				<YesNo
					label="Are life vests (PFD’s) provided?"
					value={lifeVestsProvided}
					onChange={handleChange}
					name="lifeVestsProvided"
				/>
			</form>
		</div>
	);
}

export default BoatGeneralInfo;
